.disable-button{
    .dropdown{
        .dropdown-toggle.btn{
            color: #9da3ae !important;
            border: 1px solid #9aa5ba !important;
        }
    }
}

.dropdown {
    .dropdown-toggle.btn {
        color: #3C7DFD !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 40px !important;
        padding: 0px 16px !important;
        color: #3C7DFD !important;
        height: 40px !important;
        background: #FFFFFF !important;
        border: 1px solid #3C7DFD !important;
        border-radius: 12px !important;
        display: flex;
        align-items: center;
        gap: 10px;
        &:after{
            display: none;
        }
    }

    .dropdown-menu {
        box-shadow: 0px 6px 8px 0px rgba(58, 62, 93, 0.08);
        border-radius: 12px;        
        &:empty {
            display: none;
        }

        button {
            padding: 8px 16px;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: #000000;
            &:before {
                content: "";
                display: inline-block;
                width: 16px;
                height: 16px;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Cpath d='M336 176h40a40 40 0 0140 40v208a40 40 0 01-40 40H136a40 40 0 01-40-40V216a40 40 0 0140-40h40' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M176 272l80 80 80-80M256 48v288'/%3E%3C/svg%3E");
                vertical-align: baseline;
                margin-right: 10px;
                line-height: 24px;
            }
            &:hover, &:focus {
                background-color: #E2ECFF !important;
            }
        }
    }
}