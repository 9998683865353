@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import '../theme_v1';

/*================================================
Override CSS
=================================================*/
.summary-container {
    word-break: break-word;
}

.ng-select-single .ng-value input.cb-multi-select{
    display: none;
}

@import "pdf";
@import "dropdown";
@import "datepicker";
@import "apexcharts";
@import "download-button";
@import "se-report";
@import "photo-report";

.callout {
    border-radius: 4px;
    border: 1px solid #EDEFF3;
    padding: 4px 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    &:before {
        content: "";
        background-color: #F40009;
        height: 10px;
        width: 10px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 8px;
    }
}

// TO-DO: Make loading component
.spinner-wrapper {
    position: static !important;
}

.stats-card-box-v2.BAPC {
    background: #FFFFFF;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #D4D8E1;
    box-shadow: 0px 4px 0px #FF7E6E;
    border-radius: 12px;

    .icon {
        background-color: #FF7E6E !important;
    }
}

.stats-card-box-v2.NSR {
    background: #FFFFFF;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #D4D8E1;
    box-shadow: 0px 4px 0px #58C9D0;
    border-radius: 12px;

    .icon {
        background-color: #58C9D0 !important;
    }
}

.stats-card-box-v2.NSR-PC {
    background: #FFFFFF;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #D4D8E1;
    box-shadow: 0px 4px 0px #EF86DB;
    border-radius: 12px;

    .icon {
        background-color: #EF86DB !important;
    }
}

.stats-card-box-v2.GP {
    background: #FFFFFF;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #D4D8E1;
    box-shadow: 0px 4px 0px #5FB0FF;
    border-radius: 12px;

    .icon {
        background-color: #5FB0FF !important;
    }
}



@import "dropdown-wrapper";


.tab-content .card {
    border-radius: 0 0 16px 16px;
}
.nav-tabs .nav-link {
    border: none;
}
.nav-tabs .nav-link.active {
    border-radius: 8px 8px 0px 0px;
}
.nav-tabs {
    border-bottom: none;
    flex-wrap: nowrap;
}

// https://app.asana.com/0/home/1204421777568708/1204902312067075
@media (min-width: 1443px) {
    .score-card-container.bottom {
        flex-wrap: nowrap !important;

        .col-lg-20 {
            width: min-content !important;
            flex-grow: 1;
        }
    }
}

@import "table_intage-market-size";

@import "table-deep-dive-data";

.apexcharts-legend {
    padding: 0 !important;
    .apexcharts-legend-series {
        min-width: 100px;
    }
}

.text-green {
    color: #03BF65;
}

@import "swal2";

.single-series-container.wtd {
    display: none !important;

    &.movingAverageWindowSizes-0 {
        display: block !important;
    }    
}

.modal-backdrop {
    background-color: #1A2D4F !important;
}

.top-sticky {
    z-index: 1200;
    top: 0;
    left: 0;
    position: sticky;
    .notification-bar, .app-header .navbar{
        z-index: 1200;
        position: relative;
    }    
}

