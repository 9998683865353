/*
@File: Fiva Angular 8 Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Sidemenu CSS
** - Top Navbar CSS
** - Main Content CSS
** - App Email CSS
** - App Email Read CSS
** - App Email Compose CSS
** - App Todo CSS
** - App Calendar CSS
** - App Chat CSS
** - Profile CSS
** - Gallery CSS
** - Login CSS
** - Register CSS
** - Reset Password CSS
** - Forgot Password CSS
** - Lock Screen CSS
** - Error 404 CSS
** - Error 500 CSS
** - Coming Soon CSS
** - Maintenance CSS
** - Not Authorized CSS
** - FAQ CSS
** - Pricing CSS
** - Invoice CSS
** - Timeline CSS
** - Footer CSS
*/

/*================================================
Default CSS
=================================================*/
$font-family: 'Poppins', sans-serif;
$main-color: #e1000a;
$black-color: #2a2a2a;
$white-color: #ffffff;
$font-size: 15px;
$transition: .5s;

body {
    color: $black-color;
    background-color: #EDEFF3;
    padding: 0;
    margin: 0;

    font: {
        size: $font-size;
        family: $font-family
    }

    ;
}

img {
    max-width: 100%;
    height: auto;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: $black-color;

    font: {
        family: $font-family
    }

    ;
}

a {
    transition: $transition;
    outline: 0 !important;
}

p {
    line-height: 1.8;
}

.mb-30 {
    margin-bottom: 30px;
}

.mt-20 {
    margin-top: 20px;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mg-auto {
    margin: auto;
}

button,
input {
    outline: 0 !important;
}

.btn-outline-light {
    color: #212529;
}

.btn-block {
    display: block;
    width: 100%;
}

/*dropdown-css*/
// header, datepicker, BS-dropdown-button
.dropdown-menu {
    box-shadow: -7px 8px 16px 0 rgba(55, 70, 95, .2);
    background-color: $white-color;
    border-color: #eeeeee;

    .form-control {
        border-radius: 5px;
        height: 45px;
    }
}

.comment-content {
    .dropdown-item{
        color: #1A2D4F;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;        
    }
    .dropdown-item.active,
    .dropdown-item:active {
        background-color: #EEF4FF;
    }
    .dropdown-menu {
        border-radius: 0px 0px 12px 12px;
        border-color: none;
        padding: 0;
        top: 16px !important;
        left: -16px !important;

        &.mention-menu{
            border-radius: 0px 0px 12px 12px;
            padding: 0;
            border: none;
            li {
                a {
                    color: #1A2D4F;
                    transition: none;
                }
            }
            .mention-active a {
                background-color: #EEF4FF;
            }
        }

        .dropdown-item {
            padding: 12px 16px 12px 16px;
            width: 300px;

            display: flex;
            align-items: center;

            .left {
                width: 32px;
                flex-grow: 0 !important;
                margin-right: 1em;
            }

            .right {
                .displayName {
                    color: #1A2D4F;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1.5em;
                }
                .email {
                    color: #697892;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.5em;
                }
            }

        }
    }
}

/*progress-css*/
.progress+.progress {
    margin-top: 1rem;
}

/*modal-css*/
.modal {
    p {
        line-height: 1.8;
        color: #727E8C;
        .red {
            color: #F40009;
            font-weight: bold;
        }
        .blue {
            color: #3C7DFD;
            font-weight: bold;
        }        
    }
}

/*forms*/
.form-check-input {
    margin-top: .20rem;
}

.form-control {
    &:focus {
        box-shadow: unset;
        border-color: $main-color;
    }
}

/*================================================
Sidemenu CSS
=================================================*/
// .sidemenu-area {
//     position: fixed;
//     left: 0;
//     top: 0;
//     height: 100%;
//     width: 250px;
//     z-index: 9999;
//     transition: $transition;
//     &.with-notification{
//         top: 43px;
//     }
//     .sidemenu-header {
//         background: $white-color;
//         padding: 0 20px;
//         border-bottom: 1px solid #f2f4f9;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         height: 70px;
//         width: 100%;
//         transition: $transition;

//         .navbar-brand {
//             padding: 0;

//             span {
//                 position: relative;
//                 top: 3px;
//                 color: $black-color !important;
//                 margin-left: 10px;
//                 transition: $transition;
//                 font: {
//                     size: 25px;
//                     weight: 600;
//                 };
//             }
//         }
//         .burger-menu {
//             cursor: pointer;
//             transition: $transition;

//             span {
//                 height: 1px;
//                 width: 25px;
//                 background: $black-color;
//                 display: block;
//                 margin: 6px 0;
//                 transition: all .50s ease-in-out;
//             }
//             &.active {
//                 span {
//                     &.top-bar {
//                         transform: rotate(45deg);
//                         transform-origin: 10% 10%;
//                     }
//                     &.middle-bar {
//                         opacity: 0;
//                     }
//                     &.bottom-bar {
//                         transform: rotate(-45deg);
//                         transform-origin: 10% 90%;
//                         margin-top: 5px;
//                     }
//                 }
//             }
//         }
//         .responsive-burger-menu {
//             cursor: pointer;
//             transition: $transition;

//             span {
//                 height: 1px;
//                 width: 25px;
//                 background: $black-color;
//                 display: block;
//                 margin: 6px 0;
//                 transition: all .50s ease-in-out;
//             }
//             &.active {
//                 span {
//                     &.top-bar {
//                         transform: rotate(45deg);
//                         transform-origin: 10% 10%;
//                     }
//                     &.middle-bar {
//                         opacity: 0;
//                     }
//                     &.bottom-bar {
//                         transform: rotate(-45deg);
//                         transform-origin: 10% 90%;
//                         margin-top: 5px;
//                     }
//                 }
//             }
//         }
//     }
//     .sidemenu-body {
//         max-height: calc(100% - 80px);
//         position: relative;
//         height: 100%;
//         box-shadow: 0 8px 10px 0 rgba(183, 192, 206, .10);
//         background: $white-color;
//         overflow: hidden;

//         .sidemenu-nav {
//             padding: 20px 0;
//             list-style-type: none;
//             margin-bottom: 0;

//             .nav-item-title {
//                 color: #686868;
//                 display: block;
//                 text-transform: uppercase;
//                 margin-bottom: 7px;
//                 font: {
//                     size: 13px;
//                     weight: 600;
//                 };
//                 padding: {
//                     left: 20px;
//                     right: 20px;
//                 };
//                 &:not(:first-child) {
//                     margin-top: 20px;
//                 }
//             }
//             .nav-item {
//                 .parent-nav-link{
//                     display: contents;
//                 }
//                 .nav-link {
//                     color: #7e7e7e;
//                     padding: 10px 20px;
//                     position: relative;
//                     z-index: 1;
//                     font: {
//                         size: $font-size;
//                         weight: 500;
//                     };
//                     .icon {
//                         color: $black-color;
//                         transition: $transition;
//                         display: inline-block;
//                         margin-right: 9px;
//                         font-size: 18px;
//                         position: relative;
//                         top: 2px;
//                     }
//                     .badge {
//                         background-color: #43d39e;
//                         color: $white-color;
//                         transition: $transition;
//                         margin-left: 7px;
//                         position: relative;
//                         top: -1px;
//                         padding: 4px 7px 4px 6px;
//                     }
//                     &.disabled {
//                         color: #a3a3a3;

//                         i {
//                             color: #a3a3a3;
//                         }
//                     }
//                     &::before {
//                         content: '';
//                         position: absolute;
//                         left: 0;
//                         top: 0;
//                         height: 100%;
//                         width: 0;
//                         transition: $transition;
//                         background: $main-color;
//                     }
//                     &.collapsed-nav-link {
//                         position: relative;

//                         &::after {
//                             content: "\e9f9";
//                             position: absolute;
//                             right: 15px;
//                             top: 9px;
//                             color: #7e7e7e;
//                             transition: $transition;
//                             font: {
//                                 family: "boxicons" !important;
//                                 weight: normal;
//                                 style: normal;
//                                 variant: normal;
//                                 size: 18px;
//                             };
//                         }
//                     }
//                     &:hover {
//                         background-color: #f9ecef;
//                         color: $main-color;

//                         &::before {
//                             width: 3px;
//                         }
//                         .icon {
//                             transform: rotateY(-180deg);
//                             color: $main-color;
//                         }
//                     }
//                 }
//                 &.mm-active, &.active {
//                     .nav-link {
//                         background-color: #f9ecef;
//                         color: $main-color;

//                         &::before {
//                             width: 3px;
//                         }
//                         .icon {
//                             color: $main-color;
//                         }
//                         &.collapsed-nav-link {
//                             &::after {
//                                 transform: rotate(-90deg);
//                             }
//                         }
//                     }
//                 }
//                 .sidemenu-nav-second-level {
//                     list-style-type: none;
//                     padding-left: 30px;
//                     margin-bottom: 0;

//                     .nav-item {
//                         .nav-link {
//                             color: #7e7e7e;
//                             background-color: transparent !important;
//                             padding: {
//                                 left: 0;
//                                 right: 0;
//                                 bottom: 5px;
//                             };
//                             .icon {
//                                 color: $black-color;
//                             }
//                             &::before {
//                                 display: none;
//                             }
//                             &.collapsed-nav-link {
//                                 &::after {
//                                     transform: rotate(0deg);
//                                 }
//                             }
//                             &:hover {
//                                 color: $main-color;

//                                 .icon {
//                                     color: $main-color;
//                                 }
//                             }
//                         }
//                         &.mm-active, &.active {
//                             .nav-link {
//                                 color: $main-color;

//                                 .icon {
//                                     color: $main-color;
//                                 }
//                                 &.collapsed-nav-link {
//                                     &::after {
//                                         transform: rotate(-90deg);
//                                     }
//                                 }
//                             }
//                         }
//                         .sidemenu-nav-third-level {
//                             list-style-type: none;
//                             padding-left: 15px;
//                             margin-bottom: 0;

//                             .nav-item {
//                                 .nav-link {
//                                     color: #7e7e7e;
//                                     background-color: transparent !important;
//                                     padding: {
//                                         left: 0;
//                                         right: 0;
//                                         bottom: 5px;
//                                     };
//                                     .icon {
//                                         color: $black-color;
//                                     }
//                                     &::before {
//                                         display: none;
//                                     }
//                                     &.collapsed-nav-link {
//                                         &::after {
//                                             transform: rotate(0deg);
//                                         }
//                                     }
//                                     &:hover {
//                                         color: $main-color;

//                                         .icon {
//                                             color: $main-color;
//                                         }
//                                     }
//                                 }
//                                 &.mm-active, &.active {
//                                     .nav-link {
//                                         color: $main-color;

//                                         .icon {
//                                             color: $main-color;
//                                         }
//                                         &.collapsed-nav-link {
//                                             &::after {
//                                                 transform: rotate(-90deg);
//                                             }
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     &.toggle-sidemenu-area {
//         width: 55px;

//         .sidemenu-header {
//             padding: 0 10px;

//             .navbar-brand {
//                 display: block !important;

//                 span {
//                     opacity: 0;
//                     visibility: hidden;
//                 }
//             }
//             .burger-menu {
//                 opacity: 0;
//                 visibility: hidden;
//             }
//         }
//         .sidemenu-body {
//             .sidemenu-nav {
//                 .nav-item-title {
//                     display: none;
//                 }
//                 .nav-item {
//                     .nav-link {
//                         .menu-title {
//                             display: none;
//                         }
//                         &::after {
//                             display: none;
//                         }
//                         .badge {
//                             display: none;
//                         }
//                     }
//                     .sidemenu-nav-second-level {
//                         padding-left: 20px;

//                         .nav-item {
//                             .sidemenu-nav-third-level {
//                                 padding-left: 0;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     &:hover {
//         &.toggle-sidemenu-area {
//             width: 250px;

//             .sidemenu-header {
//                 padding: 0 20px;

//                 .navbar-brand {
//                     display: flex !important;

//                     span {
//                         opacity: 1;
//                         visibility: visible;
//                     }
//                 }
//                 .burger-menu {
//                     opacity: 1;
//                     visibility: visible;
//                 }
//             }
//             .sidemenu-nav {
//                 .nav-item-title {
//                     display: block;
//                 }
//                 .nav-item {
//                     .nav-link {
//                         .menu-title {
//                             display: inline-block;
//                         }
//                         &::after {
//                             display: block;
//                         }
//                         .badge {
//                             display: inline-block;
//                         }
//                     }
//                     .sidemenu-nav-second-level {
//                         padding-left: 30px;

//                         .nav-item {
//                             .sidemenu-nav-third-level {
//                                 padding-left: 15px;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }

/*================================================
Top Navbar CSS
=================================================*/
.top-navbar {
    &.navbar {
        top: 0;

        padding: {
            left: 0;
            right: 0;
            // top: 20px;
        }

        ;

        &.with-notification {
            // top: 43px;
        }

        .responsive-burger-menu {
            cursor: pointer;
            transition: $transition;

            span {
                height: 1px;
                width: 25px;
                background: $black-color;
                display: block;
                margin: 6px 0;
                transition: all .50s ease-in-out;
            }

            &.active {
                span {
                    &.top-bar {
                        transform: rotate(45deg);
                        transform-origin: 10% 10%;
                    }

                    &.middle-bar {
                        opacity: 0;
                    }

                    &.bottom-bar {
                        transform: rotate(-45deg);
                        transform-origin: 10% 90%;
                        margin-top: 5px;
                    }
                }
            }
        }

        .nav-search-form {
            position: relative;
            width: 300px;

            label {
                display: block;
                margin-bottom: 0;
                position: absolute;
                left: 15px;
                top: 11.3px;
                font-size: 18px;
                color: #a8a8a8;
            }

            .form-control {
                height: 45px;
                border-radius: 30px;
                background-color: #eef5f9;
                border: none;
                color: $black-color;
                padding: 0 15px 0 40px;
                transition: $transition;
                box-shadow: unset;

                font: {
                    size: 14px;
                    weight: 400;
                }

                ;

                &:focus {
                    box-shadow: unset;
                    background-color: #eef5f9;

                    &::placeholder {
                        color: transparent !important;
                    }
                }

                &::placeholder {
                    color: #a8a8a8;
                    transition: $transition;
                }
            }
        }

        .nav-item {
            // margin-left: 15px;

            .nav-link {
                padding: 0;
                color: #525f80;
                font-weight: 500;

                &.dropdown-toggle {
                    &::after {
                        display: none;
                    }
                }

                .bx-chevron-down {
                    font-size: 18px;
                    position: relative;
                    display: inline-block;
                    top: 3px;
                    margin-left: -4px;
                    color: #525f80;
                }

                img {
                    width: 30px;
                    margin-right: 3px;
                }

                .badge {
                    // position: absolute;
                    // bottom: 5px;
                    // right: -15px;
                    display: block;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    font-size: 9px;
                    padding: 2px;
                    line-height: 12px;

                    &.bg-secondary {
                        background: $main-color;
                        animation: pulse-secondary 2s infinite;
                        box-shadow: 0 0 0 rgba(255, 0, 10, 0.9);
                    }

                    &.bg-primary {
                        background: #2962ff;
                        animation: pulse-primary 2s infinite;
                        box-shadow: 0 0 0 rgba(41, 98, 255, 0.9);
                    }
                }

                &.bx-fullscreen-btn {
                    font-size: 22px;
                    position: relative;
                    cursor: pointer;
                    top: 3px;
                    margin-right: 5px;

                    &:hover {
                        color: $main-color;
                    }

                    &.active {
                        i {
                            &::before {
                                content: "\ea5d";
                            }
                        }
                    }
                }
            }

            &.dropdown {
                .dropdown-menu {
                    max-width: 280px;
                    position: absolute;
                    right: 0;
                    left: auto;
                    border-radius: 2px;
                    margin-top: 20px;
                    border: none;
                    display: block;
                    transition: .3s;
                    opacity: 0;
                    visibility: hidden;
                    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
                    background-color: $white-color;

                    &.show {
                        margin-top: 10px;
                        opacity: 1;
                        visibility: visible;
                        border-radius: 0 0 12px 12px;
                    }

                    .dropdown-item {
                        color: $black-color;
                        padding: 5px 15px;
                        position: relative;

                        font: {
                            size: $font-size;
                        }

                        ;

                        &.active,
                        &:active {
                            color: $black-color;
                            background-color: transparent;
                            background-color: #f8f9fa;
                        }
                    }
                }
            }

            &:first-child {
                margin-left: 0;
            }

            &.language-switch-nav-item {
                .nav-link {
                    border: 0;
                    padding: 0;
                    background-color: transparent;
                }

                .nav-link {
                    img {
                        width: 25px;
                        margin-right: 7px;
                        position: relative;
                        top: -2px;
                        border-radius: 2px;
                    }
                }

                &.dropdown {
                    .dropdown-menu {
                        left: 50%;
                        right: auto;
                        transform: translateX(-50%);

                        .dropdown-item {
                            padding: 5px 20px;
                            font-size: 14.5px;

                            img {
                                width: 27px;
                                border-radius: 2px;
                            }
                        }
                    }
                }
            }

            &.notification-box {
                .nav-link {
                    border: 0;
                    padding: 0;
                    background-color: transparent;
                }

                .dropdown-menu {
                    width: 350px;
                    max-width: 350px;
                    border-radius: 5px 5px 0 0;
                    padding: 0;

                    .dropdown-header {
                        border-radius: 5px 5px 0 0;
                        background-color: $main-color;
                        color: $white-color;

                        padding: {
                            left: 15px;
                            right: 15px;
                            top: 9px;
                            bottom: 7px;
                        }

                        ;

                        span {
                            font: {
                                size: 13px;
                                weight: 600;
                            }

                            ;

                            &.mark-all-btn {
                                cursor: pointer;
                            }
                        }
                    }

                    .dropdown-body {
                        padding: {
                            top: 10px;
                            bottom: 10px;
                        }

                        ;

                        .dropdown-item {
                            padding: 7px 15px;

                            .icon {
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                                position: relative;
                                background: #f7f7f7;
                                text-align: center;
                                color: $main-color;
                                transition: $transition;

                                i {
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }
                            }

                            .content {
                                padding-left: 15px;

                                span {
                                    color: $black-color;
                                    margin-bottom: 1px;

                                    font: {
                                        size: 14.5px;
                                        weight: 600;
                                    }

                                    ;
                                }

                                p {
                                    font-size: 12px;
                                    color: #686868;
                                }
                            }

                            &:hover {
                                .icon {
                                    background-color: $main-color;
                                    color: $white-color;
                                }
                            }

                            &:nth-child(2) {
                                .icon {
                                    background: rgba(114, 124, 245, .1);
                                    color: #727cf5;
                                }

                                &:hover {
                                    .icon {
                                        background-color: #727cf5;
                                        color: $white-color;
                                    }
                                }
                            }

                            &:nth-child(3) {
                                .icon {
                                    background: rgba(16, 183, 89, .1);
                                    color: #10b759;
                                }

                                &:hover {
                                    .icon {
                                        background-color: #10b759;
                                        color: $white-color;
                                    }
                                }
                            }

                            &:nth-child(4) {
                                .icon {
                                    background: rgba(255, 51, 102, .1);
                                    color: #f36;
                                }

                                &:hover {
                                    .icon {
                                        background-color: #f36;
                                        color: $white-color;
                                    }
                                }
                            }

                            &:nth-child(5) {
                                .icon {
                                    background: rgba(251, 188, 6, .1);
                                    color: #fbbc06;
                                }

                                &:hover {
                                    .icon {
                                        background-color: #fbbc06;
                                        color: $white-color;
                                    }
                                }
                            }
                        }
                    }

                    .dropdown-footer {
                        text-align: center;
                        padding: 10px 15px;
                        border-top: 1px solid #eeeeee;

                        .dropdown-item {
                            color: $main-color !important;
                            padding: 0;
                            position: relative;
                            display: inline-block;
                            width: auto;
                            background-color: transparent !important;

                            font: {
                                size: 14px;
                                weight: 600;
                            }

                            ;
                        }
                    }
                }
            }

            .notification-btn {
                font-size: 20px;
                position: relative;
                margin-right: 0px;
                display: flex;
                align-items: center;

                i {
                    position: relative;
                    // top: 3px;
                    // animation: ring 4s .7s ease-in-out infinite;
                }
            }

            &.message-box {
                .nav-link {
                    border: 0;
                    padding: 0;
                    background-color: transparent;
                }

                .dropdown-menu {
                    width: 350px;
                    max-width: 350px;
                    border-radius: 5px 5px 0 0;
                    padding: 0;

                    .dropdown-header {
                        border-radius: 5px 5px 0 0;
                        background-color: #2962ff;
                        color: $white-color;

                        padding: {
                            left: 15px;
                            right: 15px;
                            top: 9px;
                            bottom: 7px;
                        }

                        ;

                        span {
                            font: {
                                size: 13px;
                                weight: 600;
                            }

                            ;

                            &.clear-all-btn {
                                cursor: pointer;
                            }
                        }
                    }

                    .dropdown-body {
                        padding: {
                            top: 10px;
                            bottom: 10px;
                        }

                        ;

                        .dropdown-item {
                            padding: 7px 15px;

                            .figure {
                                img {
                                    width: 35px;
                                    height: 35px;
                                }
                            }

                            .content {
                                width: 285px;
                                padding-left: 12px;

                                span {
                                    color: $black-color;
                                    margin-bottom: 2px;

                                    font: {
                                        size: 14.5px;
                                        weight: 600;
                                    }

                                    ;
                                }

                                .time-text {
                                    font-size: 13px;
                                    color: #686868;
                                }

                                .sub-text {
                                    font-size: 12.5px;
                                    color: #686868;
                                }
                            }

                            &:hover {
                                .icon {
                                    background-color: $main-color;
                                    color: $white-color;
                                }
                            }
                        }
                    }

                    .dropdown-footer {
                        text-align: center;
                        padding: 10px 15px;
                        border-top: 1px solid #eeeeee;

                        .dropdown-item {
                            color: #2962ff !important;
                            padding: 0;
                            position: relative;
                            display: inline-block;
                            width: auto;
                            background-color: transparent !important;

                            font: {
                                size: 14px;
                                weight: 600;
                            }

                            ;
                        }
                    }
                }
            }

            .message-btn {
                font-size: 20px;
                position: relative;
                margin-right: 7px;

                i {
                    position: relative;
                    top: 3px;
                }
            }

            &.apps-box {
                .nav-link {
                    border: 0;
                    padding: 0;
                    background-color: transparent;
                }

                .dropdown-menu {
                    width: 350px;
                    max-width: 350px;
                    border-radius: 5px 5px 0 0;
                    padding: 0;
                    left: 0;
                    right: auto;

                    .dropdown-header {
                        border-radius: 5px 5px 0 0;
                        background-color: #2962ff;
                        color: $white-color;

                        padding: {
                            left: 15px;
                            right: 15px;
                            top: 9px;
                            bottom: 7px;
                        }

                        ;

                        span {
                            font: {
                                size: 13px;
                                weight: 600;
                            }

                            ;

                            &.edit-btn {
                                cursor: pointer;
                            }
                        }
                    }

                    .dropdown-body {
                        padding: 10px 5px;

                        .dropdown-item {
                            -ms-flex: 0 0 25%;
                            flex: 0 0 25%;
                            max-width: 25%;
                            text-align: center;
                            padding: 8px 10px;

                            span {
                                margin-top: 8px;
                                font-weight: 600;
                            }
                        }
                    }

                    .dropdown-footer {
                        text-align: center;
                        padding: 10px 15px;
                        border-top: 1px solid #eeeeee;

                        .dropdown-item {
                            color: #2962ff !important;
                            padding: 0;
                            position: relative;
                            display: inline-block;
                            width: auto;
                            background-color: transparent !important;

                            font: {
                                size: 14px;
                                weight: 600;
                            }

                            ;
                        }
                    }
                }
            }
        }

        .left-nav {
            position: relative;
            top: 4px;

            .nav-item {
                .nav-link {
                    font-size: 22px;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }

        .right-nav {
            // margin-left: 20px;
            gap: 25px;
        }

        .profile-nav-item {
            .nav-link {
                border: 0;
                padding: 0;
                background-color: transparent;
            }

            .menu-profile {
                .name {
                    color: $black-color;
                    display: inline-block;
                    position: relative;
                    top: 2px;

                    font: {
                        weight: 600;
                    }

                    ;
                }

                img {
                    width: 35px;
                    height: 35px;
                    display: inline-block;

                    margin: {
                        right: 0;
                        // left: 9px;
                    }

                    ;
                }
            }

            &.dropdown {
                .dropdown-menu {
                    padding: 15px 15px 10px;
                    min-width: 230px;

                    .dropdown-header {
                        padding: 0;
                        border-bottom: 1px solid #f2f4f9;

                        .figure {
                            position: relative;

                            img {
                                width: 80px;
                                height: 80px;
                            }
                        }

                        .name {
                            display: block;
                            color: $black-color;
                            margin-bottom: 2px;

                            font: {
                                size: 17px;
                                weight: 600;
                            }

                            ;
                        }

                        .email {
                            color: #686868;
                        }
                    }

                    .dropdown-body {
                        ul {
                            padding-left: 0;
                            list-style-type: none;
                            margin-bottom: 0;
                        }

                        .nav-item {
                            margin-left: 0;

                            .nav-link {
                                color: $black-color;
                                padding: 5px 15px 5px 38px;
                                position: relative;

                                font: {
                                    size: 14px;
                                    weight: 600;
                                }

                                ;

                                i {
                                    color: #686868;
                                    transition: $transition;
                                    position: absolute;
                                    left: 15px;
                                    top: 7px;

                                    font: {
                                        size: 16px;
                                    }

                                    ;
                                }

                                &.active,
                                &:active {
                                    color: $black-color;
                                    background-color: transparent;
                                    background-color: #f8f9fa;
                                }

                                &:hover {
                                    i {
                                        transform: rotateY(-180deg);
                                    }
                                }
                            }
                        }
                    }

                    .dropdown-footer {
                        margin: 10px -15px 0;
                        padding: 10px 15px 0;
                        border-top: 1px solid #eeeeee;

                        ul {
                            padding-left: 0;
                            list-style-type: none;
                            margin-bottom: 0;
                        }

                        .nav-item {
                            .nav-link {
                                color: red;
                                padding: 5px 15px 5px 38px;
                                position: relative;

                                font: {
                                    size: 14px;
                                    weight: 600;
                                }

                                ;

                                i {
                                    color: red;
                                    transition: $transition;
                                    position: absolute;
                                    left: 15px;
                                    top: 7px;

                                    font: {
                                        size: 16px;
                                    }

                                    ;
                                }

                                &.active,
                                &:active {
                                    color: $black-color;
                                    background-color: transparent;
                                    background-color: #f8f9fa;
                                }

                                &:hover {
                                    i {
                                        transform: rotateY(-180deg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.is-sticky {
            position: fixed;
            top: 0;
            left: 250px;
            z-index: 999;
            box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.07);
            background: $white-color !important;
            animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
            width: calc(100% - 250px);

            padding: {
                left: 30px;
                right: 30px;
                top: 17px;
                bottom: 17px;
            }

            ;

            &.toggle-navbar-area {
                left: 55px;
                width: calc(100% - 55px);
            }

            &.with-notification {
                top: 43px;
            }
        }
    }
}

@keyframes ring {
    0% {
        transform: rotateZ(0);
    }

    1% {
        transform: rotateZ(30deg);
    }

    3% {
        transform: rotateZ(-28deg);
    }

    5% {
        transform: rotateZ(34deg);
    }

    7% {
        transform: rotateZ(-32deg);
    }

    9% {
        transform: rotateZ(30deg);
    }

    11% {
        transform: rotateZ(-28deg);
    }

    13% {
        transform: rotateZ(26deg);
    }

    15% {
        transform: rotateZ(-24deg);
    }

    17% {
        transform: rotateZ(22deg);
    }

    19% {
        transform: rotateZ(-20deg);
    }

    21% {
        transform: rotateZ(18deg);
    }

    23% {
        transform: rotateZ(-16deg);
    }

    25% {
        transform: rotateZ(14deg);
    }

    27% {
        transform: rotateZ(-12deg);
    }

    29% {
        transform: rotateZ(10deg);
    }

    31% {
        transform: rotateZ(-8deg);
    }

    33% {
        transform: rotateZ(6deg);
    }

    35% {
        transform: rotateZ(-4deg);
    }

    37% {
        transform: rotateZ(2deg);
    }

    39% {
        transform: rotateZ(-1deg);
    }

    41% {
        transform: rotateZ(1deg);
    }

    43% {
        transform: rotateZ(0);
    }

    100% {
        transform: rotateZ(0);
    }
}

@keyframes pulse-secondary {
    0% {
        box-shadow: 0 0 0 rgba(255, 0, 10, 0.6);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(255, 0, 10, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 0, 10, 0);
    }
}

@keyframes pulse-primary {
    0% {
        box-shadow: 0 0 0 rgba(41, 98, 255, 0.6);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(41, 98, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(41, 98, 255, 0);
    }
}

.font-weight-bold {
    font-weight: 700 !important;
}

.ml-2,
.mx-2 {
    margin-left: .5rem !important;
}

.ml-1,
.mx-1 {
    margin-left: .25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: .25rem !important;
}

/*================================================
Main Content CSS
=================================================*/
.main-content {
    transition: $transition;
    overflow: hidden;
    min-height: 100vh;

    padding: {
        right: 30px;
        left: 280px;
    }

    ;

    &.hide-sidemenu-area {
        padding-left: 85px;
    }
}

.breadcrumb-area {
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    background-color: $white-color;
    padding: 20px 25px;
    border-radius: 5px;
    display: flex;
    align-items: center;

    margin: {
        top: 20px;
        bottom: 30px;
    }

    ;

    h1 {
        margin-bottom: 0;
        position: relative;
        color: $black-color;
        padding-right: 12px;

        font: {
            size: 20px;
            weight: 600;
        }

        ;

        &::before {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 16px;
            width: 1px;
            background: #cecece;
        }
    }

    .breadcrumb {
        background-color: transparent;
        border-radius: 0;

        margin: {
            bottom: 0;
        }

        ;

        padding: {
            left: 12px;
            right: 0;
            bottom: 0;
            top: 0;
        }

        ;

        .item {
            color: #606060;
            margin-right: 25px;
            position: relative;

            font: {
                size: $font-size;
                family: $font-family;
            }

            ;

            a {
                display: inline-block;
                color: $main-color;

                &:hover {
                    color: $main-color;
                }

                i {
                    font-size: 18px;
                }
            }

            &::before {
                content: "\e9fa";
                position: absolute;
                right: -21px;
                top: 2px;
                line-height: 18px;
                color: #606060;

                font: {
                    family: "boxicons" !important;
                    weight: normal;
                    style: normal;
                    variant: normal;
                    size: 18px;
                }

                ;
            }

            &:last-child {
                margin-right: 0;

                &::before {
                    display: none;
                }
            }
        }
    }
}

/*start-stats-box-css*/
.stats-card-box {
    margin-bottom: 30px;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    background-color: $white-color;
    padding: 25px 25px 25px 115px;
    border-radius: 5px;
    position: relative;
    height: auto !important;

    .icon-box {
        width: 70px;
        height: 70px;
        border-radius: 50% !important;
        background-color: $main-color;
        position: absolute;
        left: 25px;
        top: 47%;
        transform: translateY(-47%);
        // box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, .15);
        text-align: center;
        font-size: 38px;
        color: $white-color;

        i {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            &.bx-paper-plane {
                top: 45%;
                transform: translateY(-45%);
                left: -3px;
            }
        }
    }

    @media (min-width: 1153px) {
        .icon-box {
            width: 4vw;
            height: 4vw;
            max-width: 80px;
            max-height: 80px;
        }
    }

    .sub-title {
        color: #a3a3a3;
        display: block;
        margin-bottom: 5px;

        font: {
            size: 15px;
            weight: 400;
        }

        ;
    }

    h3 {
        margin-bottom: 0;

        font: {
            size: 25px;
            weight: 600;
        }

        ;

        .badge {
            line-height: initial;
            border-radius: 0;
            color: #13bb37;
            padding: 0;

            font: {
                size: 13px;
                weight: 400;
            }

            ;

            &.badge-red {
                color: #ff4b00;
            }
        }
    }

    .progress-list {
        margin-top: 8px;

        .bar-inner {
            position: relative;
            width: 100%;
            border-bottom: 3px solid #d1d1d1;

            .bar {
                position: absolute;
                left: 0;
                top: 0;
                width: 0;
                height: 3px;
                background: $main-color;
                transition: all 2000ms ease;
            }
        }

        p {
            color: #a3a3a3;

            margin: {
                top: 6px;
                bottom: 0;
            }

            ;

            font: {
                size: 13px;
            }

            ;
        }

        .green-line {
            background-color: #13bb37 !important;
        }

        .red-line {
            background-color: #d1d1d1 !important;
        }

        .negative-line {
            float: left;
            border-bottom-color: #e1000a;
            width: 50%;
        }

        .positive-line::before {
            content: "|";
            position: absolute;
            top: -10px;
            left: -2px;
            z-index: 1;
            color: #d1d1d1;
        }

        .positive-line {
            float: right;
            width: 50%;
        }
    }
}

.down-trend {
    color: #ff4b00 !important;
}

.up-trend {
    color: #13bb37 !important;
}

.col-lg-3 {
    &:nth-child(2) {
        .stats-card-box {
            .icon-box {
                background-color: #13bb37;
                box-shadow: 1px 5px 24px 0 rgba(19, 187, 55, .15);
            }

            .progress-list {
                .bar-inner {
                    .bar {
                        background: #13bb37;
                    }
                }
            }
        }
    }

    &:nth-child(3) {
        .stats-card-box {
            .icon-box {
                background-color: #ff4b00;
                box-shadow: 1px 5px 24px 0 rgba(255, 75, 0, .15);
            }

            .progress-list {
                .bar-inner {
                    .bar {
                        background: #ff4b00;
                    }
                }
            }
        }
    }

    &:nth-child(4) {
        .stats-card-box {
            .icon-box {
                background-color: #4788ff;
                box-shadow: 1px 5px 24px 0 rgba(71, 136, 255, .15);
            }

            .progress-list {
                .bar-inner {
                    .bar {
                        background: #4788ff;
                    }
                }
            }
        }
    }
}

.ecommerce-stats-area {
    margin-bottom: 30px;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    background-color: $white-color;
    border-radius: 5px;
    padding: 30px 25px;

    .row {
        margin: {
            left: 0;
            right: 0;
        }

        ;

        .col-lg-3 {
            padding: {
                left: 0;
                right: 0;
            }

            ;

            &:nth-child(2) {
                .single-stats-card-box {
                    .icon {
                        background-color: #13bb37;
                        box-shadow: 1px 5px 24px 0 rgba(19, 187, 55, .15);
                    }
                }
            }

            &:nth-child(3) {
                .single-stats-card-box {
                    .icon {
                        background-color: #ff4b00;
                        box-shadow: 1px 5px 24px 0 rgba(255, 75, 0, .15);
                    }
                }
            }

            &:nth-child(4) {
                .single-stats-card-box {
                    border-right: none;

                    .icon {
                        background-color: #4788ff;
                        box-shadow: 1px 5px 24px 0 rgba(71, 136, 255, .15);
                    }
                }
            }
        }
    }
}

.single-stats-card-box {
    padding-left: 90px;
    position: relative;

    .icon {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background-color: $main-color;
        position: absolute;
        left: 0;
        top: 46%;
        transform: translateY(-46%);
        box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, .15);
        text-align: center;
        font-size: 38px;
        color: $white-color;

        i {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            &.bx-paper-plane {
                top: 45%;
                transform: translateY(-45%);
                left: -3px;
            }
        }
    }

    .sub-title {
        color: #a3a3a3;
        display: block;
        margin-bottom: 5px;

        font: {
            size: 15px;
            weight: 400;
        }

        ;
    }

    h3 {
        margin-bottom: 0;
        color: $main-color;
        position: relative;

        font: {
            size: 30px;
            weight: 600;
        }

        ;

        .badge {
            line-height: initial;
            border-radius: 0;
            color: #13bb37;
            background-color: rgba(49, 203, 114, 0.18);
            padding: 2px 4px 1px;
            border-radius: 5px;
            position: relative;
            bottom: 3px;

            font: {
                size: 11px;
                weight: 400;
            }

            ;

            &.badge-red {
                background-color: rgba(255, 91, 91, 0.18);
                color: #ff4b00;
            }

            i {
                display: inline-block;
                margin-right: -2px;
            }
        }
    }
}

@media (min-width: 1153px) {
    .icon-text {
        font-size: 1.2vw;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    .stats-card-box {
        height: 200px;
    }
}

/*end-stats-box-css*/

/*start-card-box-css*/
.card {
    background-color: $white-color;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    border: none;
    border-radius: 16px;
    padding: 25px;

    .card-header {
        background-color: $white-color;
        border-radius: 0;
        margin-bottom: 25px;
        padding: 0;
        border: none;

        h3 {
            margin-bottom: 0;

            font: {
                size: 18px;
                weight: 600;
            }

            ;
        }

        .dropdown {
            .dropdown-toggle {
                padding: 0;
                border: none;
                background-color: transparent;
                color: #727E8C;
                font-size: 22px;
                line-height: 15px;

                &::after {
                    display: none;
                }
            }

            .dropdown-menu {
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .05);
                background-color: $white-color;
                border: none;
                border-radius: 0;
                margin-top: 35px;
                display: block;
                opacity: 0;
                visibility: hidden;
                transition: .3s;
                transform: unset !important;
                left: auto !important;
                right: 0;

                &.show {
                    margin-top: 25px;
                    opacity: 1;
                    visibility: visible;
                }

                .dropdown-item {
                    color: $black-color;
                    padding: 5px 15px 5px 36px;
                    position: relative;

                    font: {
                        size: 14px;
                    }

                    ;

                    i {
                        color: #686868;
                        transition: $transition;
                        position: absolute;
                        left: 15px;
                        top: 7px;

                        font: {
                            size: 16px;
                        }

                        ;
                    }

                    &.active,
                    &:active {
                        color: $black-color;
                        background-color: transparent;
                        background-color: #f8f9fa;
                    }

                    &:hover {
                        i {
                            transform: rotateY(-180deg);
                        }
                    }
                }
            }
        }
    }

    .card-body {
        padding: 0;

        .youtube-video {
            iframe {
                width: 100%;
                border: none;
                height: 435px;
            }
        }

        &.border-primary {
            border-width: 1px;
            border-style: solid;
        }

        &.border-secondary {
            border-width: 1px;
            border-style: solid;
        }

        &.border-success {
            border-width: 1px;
            border-style: solid;
        }

        &.border-danger {
            border-width: 1px;
            border-style: solid;
        }

        &.border-warning {
            border-width: 1px;
            border-style: solid;
        }

        &.border-info {
            border-width: 1px;
            border-style: solid;
        }

        &.border-light {
            border-width: 1px;
            border-style: solid;
        }

        &.border-dark {
            border-width: 1px;
            border-style: solid;
        }
    }
}

.activity-card-box {
    margin-top: -20px;

    .progress-list {
        margin-top: 20px;

        span {
            display: inline-block;
            color: #919191;

            font: {
                size: 14px;
                weight: 400;
            }

            ;
        }

        .bar-inner {
            margin-top: 8px;
            position: relative;
            width: 100%;
            border-radius: 0 5px 5px 0;
            border-bottom: 5px solid #F2F4F4;

            .bar {
                position: absolute;
                left: 0;
                top: 0;
                width: 0;
                height: 5px;
                background: $main-color;
                border-radius: 0 5px 5px 0;
                transition: all 2000ms ease;
                box-shadow: 0 2px 6px 0 rgba(255, 91, 92, .6);
            }
        }
    }

    .row {
        .col-lg-6 {
            &:nth-child(2) {
                .progress-list {
                    .bar-inner {
                        .bar {
                            background-color: #33a73b;
                            box-shadow: 0 2px 6px 0 rgba(57, 218, 138, .6);
                        }
                    }
                }
            }

            &:nth-child(3) {
                .progress-list {
                    .bar-inner {
                        .bar {
                            background-color: #5f7be7;
                            box-shadow: 0 2px 6px 0 rgba(95, 123, 231, .6);
                        }
                    }
                }
            }

            &:nth-child(4) {
                .progress-list {
                    .bar-inner {
                        .bar {
                            background-color: #FF5B5C;
                            box-shadow: 0 2px 6px 0 rgba(255, 91, 92, .6);
                        }
                    }
                }
            }
        }
    }
}

.activity-timeline-content {
    ul {
        padding-left: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;

        margin: {
            left: -10px;
            right: -10px;
            bottom: 0;
            top: -20px;
        }

        ;

        li {
            flex: 0 0 50%;
            max-width: 50%;
            position: relative;
            margin-top: 20px;
            font-size: $font-size;
            color: $black-color;

            padding: {
                left: 55px;
                right: 10px;
            }

            ;

            &::before {
                position: absolute;
                left: 25px;
                content: '';
                bottom: 0;
                width: 1px;
                height: 150%;
                background-color: #eeeeee;
            }

            i {
                display: inline-block;
                width: 30px;
                height: 30px;
                line-height: 30px;
                background-color: $main-color;
                border-radius: 50%;
                color: $white-color;
                text-align: center;
                font-size: 22px;
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
            }

            span {
                display: block;
                font-size: 14px;
                color: #a3a3a3;
                margin-bottom: 2px;
            }

            &:nth-child(1) {
                &::before {
                    height: 100%;
                }
            }

            &:nth-child(2) {
                &::before {
                    height: 100%;
                }

                i {
                    background-color: #13bb37;
                }
            }

            &:nth-child(3) {
                i {
                    background-color: #4788ff;
                }
            }

            &:nth-child(4) {
                i {
                    background-color: #ffc107;
                }
            }
        }
    }
}

.best-sales-box {
    ul {
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 0;

        li {
            color: #9d9d9d;
            font-size: 14px;
            margin-bottom: 4px;

            &:last-child {
                margin-bottom: 0;
            }

            span {
                display: inline-block;
                min-width: 20px;
                margin-right: 3px;
            }

            .bar-inner {
                position: relative;
                width: 100%;
                border-radius: 0 5px 5px 0;
                border-bottom: 5px solid #F2F4F4;

                .bar {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 0;
                    height: 5px;
                    background: $main-color;
                    border-radius: 0 5px 5px 0;
                    transition: all 2000ms ease;
                    box-shadow: 0 2px 6px 0 rgba(255, 91, 92, .6);
                }
            }
        }
    }
}

.browser-used-box {
    margin: {
        left: -25px;
        right: -25px;
    }

    ;

    table {
        margin-bottom: 0;

        thead {
            th {
                vertical-align: middle;
                white-space: nowrap;

                border: {
                    bottom: 1px solid #eeeeee;
                    top: 1px solid #eeeeee;
                }

                ;
                color: $black-color;

                font: {
                    size: $font-size;
                    weight: 500;
                }

                ;

                padding: {
                    left: 15px;
                    right: 15px;
                    top: 7px;
                    bottom: 7px;
                }

                ;

                &:first-child {
                    padding-left: 25px;
                }

                &:last-child {
                    padding-right: 25px;
                }
            }
        }

        tbody {
            td {
                vertical-align: bottom;
                border: none;
                white-space: nowrap;
                color: $black-color;

                font: {
                    size: 14px;
                    weight: 400;
                }

                ;

                padding: {
                    left: 15px;
                    right: 15px;
                    top: 12px;
                    bottom: 0;
                }

                ;

                &:first-child {
                    padding-left: 25px;
                }

                &:last-child {
                    padding-right: 25px;
                }

                i {
                    color: #13bb37;
                    display: inline-block;
                    margin-right: -2px;

                    &.red {
                        color: #ff4b00;
                    }
                }
            }
        }
    }
}

.browser-statistics-box {
    .box {
        padding-bottom: 15px;
        border-bottom: 1px solid #eeeeee;
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }

        &:first-child {
            margin-top: 7px;
        }

        .img {
            background-color: #f3f3f3;
            border-radius: 50%;
            text-align: center;
            width: 45px;
            height: 40px;
            line-height: 37px;
        }

        .content {
            margin-left: 15px;
            width: 100%;

            h5 {
                color: $black-color;

                font: {
                    size: $font-size;
                    weight: 600;
                }

                ;
            }

            .bar-inner {
                position: relative;
                width: 100%;
                border-bottom: 3px solid #d1d1d1;
                border-radius: 5px;

                .bar {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 0;
                    height: 3px;
                    background: $main-color;
                    transition: all 2000ms ease;
                    box-shadow: 0 2px 6px 0 rgba(255, 91, 92, .6);
                    border-radius: 5px;
                }
            }

            .count-box {
                margin-left: 15px;
                color: $black-color;
            }
        }

        &:nth-child(2) {
            .content {
                .bar-inner {
                    .bar {
                        background-color: #33a73b;
                        box-shadow: 0 2px 6px 0 rgba(57, 218, 138, 0.6);
                    }
                }
            }
        }

        &:nth-child(3) {
            .content {
                .bar-inner {
                    .bar {
                        background-color: #5f7be7;
                        box-shadow: 0 2px 6px 0 rgba(95, 123, 231, 0.6);
                    }
                }
            }
        }

        &:nth-child(4) {
            .content {
                .bar-inner {
                    .bar {
                        background-color: #FF5B5C;
                        box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
                    }
                }
            }
        }

        &:nth-child(5) {
            .content {
                .bar-inner {
                    .bar {
                        background-color: #33a73b;
                        box-shadow: 0 2px 6px 0 rgba(57, 218, 138, 0.6);
                    }
                }
            }
        }

        &:nth-child(7) {
            .content {
                .bar-inner {
                    .bar {
                        background-color: #5f7be7;
                        box-shadow: 0 2px 6px 0 rgba(95, 123, 231, 0.6);
                    }
                }
            }
        }

        &:nth-child(8) {
            .content {
                .bar-inner {
                    .bar {
                        background-color: #FF5B5C;
                        box-shadow: 0 2px 6px 0 rgba(255, 91, 92, 0.6);
                    }
                }
            }
        }
    }
}

.new-product-box {
    border-radius: 5px;
    margin-bottom: 25px;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    background-color: $white-color;
    padding: 21.5px 15px;
    text-align: center;
    color: #828D99;

    font: {
        size: 16px;
        weight: 400;
    }

    ;

    .icon {
        width: 45px;
        height: 45px;
        background-color: #fbd8d7;
        color: $main-color;
        border-radius: 50%;
        position: relative;
        transition: $transition;
        font-size: 22px;

        margin: {
            left: auto;
            right: auto;
            bottom: 10px;
        }

        ;

        i {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            margin: {
                left: auto;
                right: auto;
            }

            ;
        }
    }

    .sub-text {
        color: $black-color;
        margin-top: 3px;

        font: {
            size: 17px;
        }

        ;
    }

    &:hover {
        .icon {
            background-color: $main-color;
            color: $white-color;
        }
    }
}

.new-user-box {
    margin-bottom: 25px;
    border-radius: 5px;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    background-color: $white-color;
    padding: 21.5px 15px;
    text-align: center;
    color: #828D99;

    font: {
        size: 16px;
        weight: 400;
    }

    ;

    .icon {
        width: 45px;
        height: 45px;
        background-color: #d0f1d7;
        color: #13bb37;
        border-radius: 50%;
        position: relative;
        transition: $transition;
        font-size: 22px;

        margin: {
            left: auto;
            right: auto;
            bottom: 10px;
        }

        ;

        i {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            margin: {
                left: auto;
                right: auto;
            }

            ;
        }
    }

    .sub-text {
        color: $black-color;
        margin-top: 3px;

        font: {
            size: 17px;
        }

        ;
    }

    &:hover {
        .icon {
            background-color: #13bb37;
            color: $white-color;
        }
    }
}

.upcoming-product-box {
    border-radius: 5px;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    background-color: $white-color;
    padding: 21.5px 15px;
    text-align: center;
    color: #828D99;

    font: {
        size: 16px;
        weight: 400;
    }

    ;

    .icon {
        width: 45px;
        height: 45px;
        background-color: #dbe5f7;
        color: #4788ff;
        border-radius: 50%;
        position: relative;
        transition: $transition;
        font-size: 22px;

        margin: {
            left: auto;
            right: auto;
            bottom: 10px;
        }

        ;

        i {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            margin: {
                left: auto;
                right: auto;
            }

            ;
        }
    }

    .sub-text {
        color: $black-color;
        margin-top: 3px;

        font: {
            size: 17px;
        }

        ;
    }

    &:hover {
        .icon {
            background-color: #4788ff;
            color: $white-color;
        }
    }
}

.widget-todo-list {
    border-top: 1px solid #eeeeee;
    padding-top: 18px !important;

    margin: {
        left: -25px;
        right: -25px;
        bottom: -10px;
        top: -10px;
    }

    ;

    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            position: relative;
            transition: $transition;

            padding: {
                left: 60px;
                right: 110px;
                top: 9px;
                bottom: 10px;
            }

            ;

            .checkbox {
                position: absolute;
                left: 25px;
                top: 50%;
                transform: translateY(-50%);
            }

            .todo-item-title {
                position: relative;
                padding-left: 53px;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    position: absolute;
                    cursor: pointer;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                h3 {
                    margin-bottom: 2px;

                    font: {
                        size: 16px;
                        weight: 600;
                    }

                    ;
                }

                p {
                    color: #919191;
                    font-size: $font-size;
                    margin-bottom: 0;
                }
            }

            .todo-item-action {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 25px;

                a {
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    line-height: 32px;
                    border-radius: 3px;
                    background-color: #c6e8cd;
                    color: #13bb37;
                    text-align: center;
                    margin-left: 3px;

                    &:hover {
                        background-color: #13bb37;
                        color: $white-color;
                    }

                    &.delete {
                        background-color: #f9d0d9 !important;
                        color: #e1000a !important;

                        &:hover {
                            background-color: #e1000a !important;
                            color: $white-color !important;
                        }
                    }
                }
            }

            &:hover {
                background-color: #F2F4F4;
            }

            &:nth-child(2) {
                .todo-item-action {
                    a {
                        background-color: #d1def5;
                        color: #4788ff;

                        &:hover {
                            background-color: #4788ff;
                            color: $white-color;
                        }
                    }
                }
            }

            &:nth-child(3) {
                .todo-item-action {
                    a {
                        background-color: #f5d1c4;
                        color: #fb4a06;

                        &:hover {
                            background-color: #fb4a06;
                            color: $white-color;
                        }
                    }
                }
            }

            &:nth-child(4) {
                .todo-item-action {
                    a {
                        background-color: #d1def5;
                        color: #4788ff;

                        &:hover {
                            background-color: #4788ff;
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
}

.cbx {
    margin: auto;
    user-select: none;
    cursor: pointer;

    span {
        display: inline-block;
        vertical-align: middle;
        transform: translate3d(0, 0, 0);

        &:first-child {
            position: relative;
            width: 18px;
            height: 18px;
            border-radius: 3px;
            transform: scale(1);
            vertical-align: middle;
            border: 1px solid #DFE3E7;
            transition: all 0.2s ease;

            svg {
                position: absolute;
                top: 3px;
                left: 2px;
                fill: none;
                stroke: $white-color;
                stroke-width: 2;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-dasharray: 16px;
                stroke-dashoffset: 16px;
                transition: all 0.3s ease;
                transition-delay: 0.1s;
                transform: translate3d(0, 0, 0);
            }

            &::before {
                content: "";
                width: 100%;
                height: 100%;
                background: $main-color;
                display: block;
                transform: scale(0);
                opacity: 1;
                border-radius: 50%;
            }

            &:hover {
                span {
                    &:first-child {
                        border-color: $main-color;
                    }
                }
            }
        }
    }

    .inp-cbx:checked+& span:first-child {
        background: $main-color;
        border-color: $main-color;
        animation: wave 0.4s ease;
    }

    .inp-cbx:checked+& span:first-child svg {
        stroke-dashoffset: 0;
    }

    .inp-cbx:checked+& span:first-child:before {
        transform: scale(3.5);
        opacity: 0;
        transition: all 0.6s ease;
    }

    &.active {
        .inp-cbx+& span:first-child {
            background: $main-color;
            border-color: $main-color;
            animation: wave 0.4s ease;
        }

        .inp-cbx+& span:first-child svg {
            stroke-dashoffset: 0;
        }

        .inp-cbx+& span:first-child:before {
            transform: scale(3.5);
            opacity: 0;
            transition: all 0.6s ease;
        }
    }
}

@keyframes wave {
    50% {
        transform: scale(0.9);
    }
}

.recent-orders-box {
    table {
        margin-bottom: 0;

        thead {
            th {
                text-align: center;
                white-space: nowrap;
                padding: 0 15px 15px;

                border: {
                    bottom: 2px solid #f6f6f7;
                    top: none;
                }

                ;

                font: {
                    size: $font-size;
                }

                ;

                &:first-child {
                    text-align: left;
                    padding-left: 0;
                }
            }
        }

        tbody {
            tr {
                transition: $transition;

                td {
                    text-align: center;
                    padding: 15px;
                    vertical-align: middle;
                    white-space: nowrap;

                    border: {
                        bottom: 1px solid #f6f6f7;
                        top: none;
                    }

                    ;

                    &:first-child {
                        text-align: left;
                        padding-left: 0;
                    }

                    font: {
                        size: 14.4px;
                    }

                    ;

                    .badge {
                        padding: 0;
                        background-color: transparent !important;
                        color: $black-color;

                        font: {
                            weight: 600;
                            size: 14.4px;
                        }

                        ;

                        &.bg-primary {
                            color: #13bb37;
                        }

                        &.bg-success {
                            color: #f59d03;
                        }

                        &.bg-danger {
                            color: #ff5c75;
                        }
                    }

                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 5px;
                    }

                    &.name {
                        font-weight: 600;
                    }
                }

                &:last-child {
                    td {
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

.new-customer-box {
    table {
        margin-bottom: 0;

        thead {
            th {
                text-align: center;

                border: {
                    bottom: 2px solid #f6f6f7;
                    top: none;
                }

                ;
                padding: 0 15px 14px;

                font: {
                    size: $font-size;
                }

                ;

                &:first-child {
                    text-align: left;
                    padding-left: 0;
                }
            }
        }

        tbody {
            tr {
                transition: $transition;

                td {
                    text-align: center;
                    padding: 13px 15px;
                    vertical-align: middle;

                    border: {
                        bottom: 1px solid #f6f6f7;
                        top: none;
                    }

                    ;
                    white-space: nowrap;

                    &:first-child {
                        text-align: left;
                        padding-left: 0;
                    }

                    font: {
                        size: 14.4px;
                    }

                    ;

                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        margin-right: 5px;
                    }

                    .text-success {
                        font-size: 16px;
                    }

                    .text-danger {
                        font-size: 16px;
                    }

                    .text-warning {
                        font-size: 17px;
                    }

                    &.name {
                        font-weight: 600;
                    }
                }

                &:last-child {
                    td {
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

.revenue-summary-content {
    p {
        font-size: 14px;
    }

    h5 {
        font: {
            size: 20px;
        }

        ;

        .primary-text {
            color: #13bb37;
            display: inline-block;
            margin-left: 1px;
            position: relative;
            bottom: 1px;
            font-size: 12px;
        }

        .danger-text {
            color: #ff4b00;
            display: inline-block;
            margin-left: 1px;
            position: relative;
            bottom: 1px;
            font-size: 12px;
        }
    }
}

.greetings-card-box {
    .image {
        margin-right: 20px;
        margin-top: 60px;
    }

    .content {
        margin-left: 10px;
        text-align: center;

        .icon {
            text-align: center;
            width: 65px;
            height: 65px;
            position: relative;
            border-radius: 50%;
            background-color: #fcdedf;
            color: $main-color;
            font-size: 35px;
            transition: $transition;

            margin: {
                left: auto;
                right: auto;
            }

            ;

            i {
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                transform: translateY(-50%);

                margin: {
                    left: auto;
                    right: auto;
                }

                ;
            }
        }

        h3 {
            margin-bottom: 10px;

            font: {
                size: 25px;
                weight: 600;
            }

            ;
        }

        p {
            color: #727E8C;
        }
    }

    &:hover {
        .content {
            .icon {
                background-color: $main-color;
                color: #fcdedf;
            }
        }
    }
}

.social-marketing-box {
    .list {
        position: relative;
        border-bottom: 1px solid #eeeeee;
        margin-bottom: 12.5px;

        padding: {
            bottom: 12.5px;
            left: 50px;
        }

        ;

        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
            padding-bottom: 0;
        }

        .icon {
            width: 38px;
            height: 40px;
            position: absolute;
            border-radius: 3px;
            left: 0;
            top: 1px;
            font-size: 23px;
            text-align: center;
            color: $white-color;

            i {
                position: absolute;
                left: 0;
                right: 0;
                top: 45%;
                transform: translateY(-45%);
            }

            &.facebook {
                background-color: #4267b2;
            }

            &.twitter {
                background-color: #1da1f2;
            }

            &.instagram {
                background-color: #A1755C;
            }

            &.linkedin {
                background-color: #0077B5;
            }

            &.youtube {
                background-color: #ff0000;
            }

            &.github {
                background-color: #2962ff;
            }
        }

        h4 {
            font: {
                size: 15px;
                weight: 600;
            }

            ;

            a {
                color: $black-color;
                text-decoration: none;

                &:hover {
                    color: $main-color;
                }
            }
        }

        p {
            color: #828D99;
        }

        .stats {
            position: absolute;
            right: 0;
            top: 14px;
            font-size: 14px;
        }
    }
}

.top-rated-product-box {
    ul {
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 0;

        .single-product {
            position: relative;
            border-bottom: 1px solid #f6f6f7;
            margin-bottom: 15px;

            padding: {
                left: 70px;
                bottom: 15px;
                right: 45px;
            }

            ;

            .image {
                position: absolute;
                left: 0;
                top: 0;

                img {
                    border: 1px solid #f3f3f3;
                    width: 55px;
                    height: 55px;
                }
            }

            h4 {
                font: {
                    size: $font-size;
                    weight: 600;
                }

                ;

                a {
                    color: $black-color;
                    text-decoration: none;

                    &:hover {
                        color: $main-color;
                    }
                }
            }

            p {
                color: #686c71;
            }

            .price {
                color: $main-color;
            }

            .rating {
                i {
                    color: #ffce00;
                    font-size: 14px;
                }
            }

            .view-link {
                position: absolute;
                top: 20px;
                right: 0;
                border: 1px solid $main-color;
                color: $main-color;
                height: 30px;
                width: 30px;
                text-align: center;
                line-height: 31px;
                border-radius: 50%;
                font-size: 15px;

                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }

            &:last-child {
                border-bottom: none;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
}

.alert {
    .alert-link {
        &:hover {
            text-decoration: underline;
        }
    }
}

.demo-code-preview {
    border: 1px solid #ebebeb;
    padding: 20px 25px 15px;
    margin-top: 2rem;
    position: relative;
    background-color: $white-color;
    border-radius: 5px;

    &::before {
        content: attr(data-label);
        display: block;
        position: absolute;
        top: -9px;
        left: 20px;
        letter-spacing: 1px;
        background-color: $white-color;
        font-size: 11px;
        padding: 0 5px;
        color: $main-color;
    }

    .highlight {
        .btn {
            &.btn-clipboard {
                position: absolute;
                right: 8px;
                top: 8px;
                padding: 0;
                box-shadow: unset !important;
            }
        }

        pre {
            margin-bottom: 0;
            font-family: $font-family;

            .c {
                color: #999;
            }

            .nt {
                color: #905;
            }

            .na {
                color: #690;
            }

            .s {
                color: #07a;
            }
        }
    }
}

.badge-card-box {
    .card-header {
        margin-bottom: 17px;
    }
}

.button-card-box {
    .card-header {
        margin-bottom: 17px;
    }
}

.button-group-card-box {
    .card-header {
        margin-bottom: 17px;
    }
}

.dropdowns-card-box {
    .card-header {
        margin-bottom: 17px;
    }
}

.popovers-card-box {
    .card-header {
        margin-bottom: 17px;
    }
}

.tooltips-card-box {
    .card-header {
        margin-bottom: 17px;
    }
}

.spinners-card-box {
    .card-header {
        margin-bottom: 17px;
    }
}

.collapse-card-box {
    .card-header {
        margin-bottom: 17px;
    }
}

.accordion-box {
    .accordion {
        list-style-type: none;
        padding-left: 0;

        margin: {
            bottom: 0;
        }

        ;

        .accordion-item {
            display: block;
            background: #f5f5ff;
            border: none;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .accordion-title {
            padding: 12px 40px 10px 20px;
            color: $black-color;
            position: relative;
            text-decoration: none;
            display: block;

            font: {
                size: $font-size;
                weight: 600;
            }

            ;

            i {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 20px;
            }

            &.active {
                background-color: $main-color;
                color: $white-color;

                i {
                    &::before {
                        content: "\eaf8";
                    }
                }
            }

            &:hover {
                background-color: $main-color;
                color: $white-color;
            }
        }

        .accordion-content {
            display: none;
            position: relative;
            padding: 15px;
            font-size: $font-size;
            color: #1f1b1b;
            line-height: 1.8;
            background-color: #fafafa;

            &.fast {
                display: block;
            }
        }
    }
}

.boxicons-list {
    .icon-box {
        margin-bottom: 30px;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
        outline: 0;

        padding: {
            left: 15px;
            right: 15px;
        }

        ;

        .icon-box-inner {
            background-color: $white-color;
            border-radius: 5px;
            padding: 20px 25px;
            outline: 0;
            box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);

            .icon-base {
                display: inline-block;
                margin-right: 5px;
                font-size: 25px;
                position: relative;
                top: 5px;
                color: $black-color;
                transition: $transition;
            }

            .icon-box-name {
                color: #6084a4;
                display: inline-block;
            }

            &:hover {
                .icon-base {
                    transform: scale(1.2);
                }
            }
        }
    }
}

.feather-icons-list {
    .icon-box {
        margin-bottom: 30px;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
        outline: 0;

        padding: {
            left: 15px;
            right: 15px;
        }

        ;

        .icon-box-inner {
            background-color: $white-color;
            border-radius: 5px;
            padding: 20px 25px;
            outline: 0;
            box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);

            .icon-base {
                display: inline-block;
                margin-right: 5px;
                color: $black-color;
                transition: $transition;
            }

            .icon-box-name {
                color: #6084a4;
                display: inline-block;
                position: relative;
                top: 1.5px;
            }

            &:hover {
                .icon-base {
                    transform: scale(1.2);
                }
            }
        }
    }
}

.grid-bg-example {
    .row {
        margin: {
            left: 0;
            right: 0;
        }

        ;

        +.row {
            margin-top: 1rem;
        }
    }

    .row>.col,
    .row>[class^=col-] {
        padding-top: .75rem;
        padding-bottom: .75rem;
        background-color: rgba(86, 61, 124, .15);
        border: 1px solid rgba(86, 61, 124, .2);
    }
}

.bd-example-row-flex-cols {
    .row {
        min-height: 10rem;
        background-color: rgba(255, 0, 0, .1);
        margin-top: 1rem;

        &:first-child {
            margin-top: 0;
        }
    }
}

.bd-example-border-utils {
    [class^=border] {
        display: inline-block;
        width: 5rem;
        height: 5rem;
        margin: .25rem;
        background-color: #f5f5f5;
    }

    &.bd-example-border-utils-0 {
        [class^=border] {
            border: 1px solid #dee2e6;
        }
    }
}

#map {
    iframe {
        height: 500px;
        width: 100%;
        border: none;
    }
}

/*end-card-box-css*/

/*start-welcome-area*/
.welcome-area {
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    background-color: $white-color;
    border-radius: 5px;

    padding: {
        left: 25px;
    }

    ;

    margin: {
        top: 20px;
        bottom: 30px;
    }

    ;
}

.welcome-content {
    h1 {
        font: {
            size: 40px;
            weight: 600;
        }

        ;
    }

    p {
        color: #646262;

        font: {
            size: 18px;
        }

        ;
    }
}

.welcome-img {
    text-align: center;
    padding-top: 20px;

    img {
        width: 100%;
    }
}

/*end-welcome-area*/

/*start-all-chart-style-css*/
#website-analytics-chart {
    &.extra-margin {
        margin: -20px 0 -15px -20px;
    }

    .apexcharts-toolbar {
        display: none;
    }

    .apexcharts-legend {
        bottom: 0 !important;
    }
}

#emailSend-chart {
    &.extra-margin {
        margin: -15px 0 -15px 0;
    }
}

#activity-timeline-chart {
    .apexcharts-legend-series {
        margin-bottom: 6px !important;

        &:last-child {
            margin-bottom: 0 !important;
        }

        .apexcharts-legend-marker {
            margin-right: 5px;
        }
    }
}

#traffic-source-chart {
    &.extra-margin {
        margin: -20px 0 -25px -20px;
    }
}

#revenue-growth-chart {
    &.extra-margin {
        margin: -20px 0 -25px -20px;

        .apexcharts-toolbar {
            display: none;
        }
    }
}

#revenue-summary-chart {
    &.extra-margin {
        margin: 0 0 -15px -20px;

        .apexcharts-toolbar {
            display: none;
        }
    }

    .apexcharts-legend {
        bottom: 0 !important;
    }
}

#client-recollection-chart {
    &.extra-margin {
        margin: -30px 0 -15px -20px;
    }

    .apexcharts-toolbar {
        display: none;
    }

    .apexcharts-legend {
        bottom: 0 !important;
    }
}

#apex-chart-datetime-x-axis {
    .toolbar {
        margin-left: 20px;
    }

    button {
        background: $white-color;
        color: #222222;
        border: 1px solid #e7e7e7;
        border-bottom: 2px solid #dddddd;
        border-radius: 2px;
        padding: 4px 17px;

        &.active {
            color: $white-color;
            background: #008FFB;
            border: 1px solid blue;
            border-bottom: 2px solid blue;
        }
    }
}

.chartjs-colors {
    .bg-purple {
        background-color: #c679e3 !important;
    }

    .bg-secondary {
        background-color: #f48665 !important;
    }

    .bg-pink {
        background-color: #FF1494 !important;
    }

    .bg-success-light {
        background-color: rgba(80, 196, 85, .3) !important;
    }

    .bg-success-light {
        background-color: rgba(63, 81, 181, .3) !important;
    }
}

/*end-all-chart-style-css*/

/*start-all-maps-style-css*/
// #world-map-markers {
//     height: 398px;
// }

/*end-all-maps-style-css*/

/*================================================
App Email CSS
=================================================*/
.email-content-area {
    margin-bottom: 30px;

    .sidebar-left {
        float: left;

        .sidebar {
            .sidebar-content {
                background-color: $white-color;
                box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
                padding: 25px;
                width: 260px;
                height: calc(100vh - 201px);

                .email-menu {
                    .btn {
                        border: none;
                        padding: 12px 20px 11px;
                        position: relative;
                        transition: $transition;

                        font: {
                            size: $font-size;
                            weight: 600;
                        }

                        ;

                        i {
                            font-size: 20px;
                            position: absolute;

                            margin: {
                                left: -23px;
                            }

                            ;
                        }
                    }

                    .btn-primary {
                        color: $white-color;
                        background-color: $main-color;

                        &:not(:disabled):not(.disabled).active,
                        &:not(:disabled):not(.disabled):active,
                        .show>&.dropdown-toggle {
                            background-color: $main-color;
                            color: $white-color;
                            box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, .2);
                        }

                        &:not(:disabled):not(.disabled).active:focus,
                        &:not(:disabled):not(.disabled):active:focus,
                        .show>&.dropdown-toggle:focus {
                            box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, .2);
                        }

                        &:hover,
                        &:focus {
                            background-color: $main-color;
                            color: $white-color;
                            box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, .2);
                        }
                    }

                    .list-group-messages {
                        .list-group-item {
                            text-decoration: none;

                            padding: {
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 29px;
                            }

                            ;
                            margin-bottom: 16px;
                            background-color: $white-color;
                            border: none !important;
                            color: $black-color;
                            border-radius: 0 !important;

                            font: {
                                size: 16px;
                                weight: 600;
                            }

                            ;

                            i {
                                position: absolute;
                                left: 0;
                                transition: $transition;
                                top: 0;
                                font-size: 22px;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }

                            &.active {
                                background-color: $white-color;
                                color: $main-color;
                            }

                            .badge {
                                transition: $transition;
                                margin-left: 4px;
                                position: relative;
                                top: -1px;
                                padding: 4px 6px 4px 6px;
                            }

                            &:hover {
                                color: $main-color;

                                i {
                                    transform: rotateY(-180deg);
                                    color: $main-color;
                                }
                            }
                        }
                    }

                    .list-group-label {
                        letter-spacing: 1px;
                        color: #828D99;

                        margin: {
                            top: 25px;
                            bottom: 15px;
                        }

                        ;

                        font: {
                            size: 14px;
                            weight: 600;
                        }

                        ;
                    }

                    .list-group-labels {
                        .list-group-item {
                            text-decoration: none;

                            padding: {
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 18px;
                            }

                            ;
                            margin-bottom: 12px;
                            background-color: $white-color;
                            border: none !important;
                            color: $black-color;
                            border-radius: 0 !important;

                            font: {
                                size: 16px;
                                weight: 600;
                            }

                            ;

                            &::before {
                                width: 10px;
                                height: 10px;
                                border-radius: 50%;
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 6px;
                                border: 2px solid $main-color;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }

                            &.active {
                                background-color: $white-color;
                                color: $main-color;
                            }

                            &:hover {
                                color: $main-color;
                            }

                            &:nth-child(2) {
                                &::before {
                                    border-color: #f79921;
                                }
                            }

                            &:nth-child(3) {
                                &::before {
                                    border-color: #1ebe40;
                                }
                            }

                            &:nth-child(4) {
                                &::before {
                                    border-color: #40fefe;
                                }
                            }

                            &:nth-child(5) {
                                &::before {
                                    border-color: #707070;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .content-right {
        float: right;
        width: calc(100% - 290px);

        .email-list-wrapper {
            .email-list {
                background-color: $white-color;
                box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);

                .email-list-header {
                    padding: 15px 20px;
                    border-bottom: 1px solid #e8ebf1;

                    .header-left {
                        position: relative;
                        top: 3px;

                        .checkbox {
                            position: relative;
                            top: -2px;
                        }

                        .btn-icon {
                            width: 34px;
                            height: 34px;
                            background-color: #f3f3f3;
                            transition: $transition;
                            position: relative;
                            border: none;
                            padding: 0;
                            font-size: 18px;
                            border-radius: 3px;

                            i {
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 47%;
                                transform: translateY(-47%);
                            }

                            &:hover {
                                color: $white-color;
                                background-color: $main-color;
                            }

                            &::after {
                                display: none;
                            }
                        }

                        .dropdown {
                            .dropdown-menu {
                                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .05);
                                background-color: $white-color;
                                border: none;
                                border-radius: 0;
                                margin-top: 35px;
                                display: block;
                                opacity: 0;
                                visibility: hidden;
                                transition: .3s;
                                transform: unset !important;

                                &.show {
                                    margin-top: 45px;
                                    opacity: 1;
                                    visibility: visible;
                                }

                                .dropdown-item {
                                    color: $black-color;
                                    padding: 5px 15px 5px 36px;
                                    position: relative;

                                    font: {
                                        size: 14px;
                                    }

                                    ;

                                    i {
                                        color: #686868;
                                        transition: $transition;
                                        position: absolute;
                                        left: 15px;
                                        top: 7px;

                                        font: {
                                            size: 16px;
                                        }

                                        ;
                                    }

                                    &.active,
                                    &:active {
                                        color: $black-color;
                                        background-color: transparent;
                                        background-color: #f8f9fa;
                                    }

                                    &:hover {
                                        i {
                                            transform: rotateY(-180deg);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .header-right {
                        position: relative;

                        label {
                            position: absolute;
                            left: 15px;
                            color: #71738d;
                            margin-bottom: 0;
                            top: 14px;
                        }

                        .form-control {
                            height: 45px;
                            border-radius: 30px;
                            background-color: #eef5f9;
                            border: none;
                            color: $black-color;
                            padding: 2px 15px 0 39px;
                            transition: $transition;
                            box-shadow: unset;

                            font: {
                                size: 14px;
                                weight: 400;
                            }

                            ;

                            &:focus {
                                box-shadow: unset;
                                background-color: #eef5f9;

                                &::placeholder {
                                    color: transparent;
                                }
                            }

                            &::placeholder {
                                color: #a8a8a8;
                                transition: $transition;
                            }
                        }
                    }
                }

                .list-wrapper {
                    height: calc(100vh - 343px);
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style-type: none;

                    .email-list-item {
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #e8ebf1;
                        padding: 16.1px 20px;
                        width: 100%;
                        position: relative;
                        background-color: $white-color;
                        font-size: $font-size;
                        transition: $transition;

                        &:last-child {
                            border-bottom: none;
                        }

                        .email-list-actions {
                            .checkbox {
                                position: relative;
                                top: 2px;
                            }

                            .favorite {
                                display: inline-block;
                                font-size: 20px;
                                cursor: pointer;
                                color: #C7CFD6;

                                margin: {
                                    top: 8px;
                                    bottom: -5px;
                                }

                                ;

                                &.active {
                                    color: #FDAC41;

                                    i {
                                        &::before {
                                            content: "\ed99";
                                        }
                                    }
                                }
                            }
                        }

                        .email-list-detail {
                            text-decoration: none;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-grow: 1;
                            flex-wrap: wrap;
                            position: relative;

                            div {
                                position: relative;
                                padding-left: 65px;
                                margin-top: -2px;
                            }

                            img {
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 50px;
                                height: 50px;
                                border-radius: 50%;
                            }

                            .from {
                                color: $black-color;

                                font: {
                                    size: 16px;
                                    weight: 600;
                                }

                                ;
                            }

                            .msg {
                                color: #71738d;
                                font-size: $font-size;
                            }

                            .date {
                                color: $black-color;
                                font-weight: 600;
                            }
                        }

                        &.read {
                            background-color: #f7f7f7;

                            .email-list-detail {
                                .from {
                                    color: #616161;
                                }

                                .msg {
                                    color: #71738d;
                                }

                                .date {
                                    color: #616161;
                                }
                            }
                        }

                        &:hover {
                            box-shadow: 0 0 10px 0 rgba(58, 70, 93, .25);
                            transition: $transition;
                            z-index: 1;
                        }
                    }
                }

                .email-list-footer {
                    border-top: 1px solid #e8ebf1;
                    padding: 15px 20px;

                    p {
                        color: #71738d;
                    }

                    .email-pagination-btn {
                        margin-bottom: -5px;

                        button {
                            width: 34px;
                            height: 34px;
                            background-color: #f3f3f3;
                            transition: $transition;
                            position: relative;
                            border: none;
                            padding: 0;
                            font-size: 18px;
                            border-radius: 5px;
                            margin-left: 5px;

                            i {
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }

                            &:hover {
                                color: $white-color;
                                background-color: $main-color;
                            }

                            &.email-pagination-prev {
                                border-radius: 5px 0 0 5px;
                            }

                            &.email-pagination-next {
                                border-radius: 0 5px 5px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*================================================
App Email Read CSS
=================================================*/
.email-read-content-area {
    margin-bottom: 30px;

    .sidebar-left {
        float: left;

        .sidebar {
            .sidebar-content {
                background-color: $white-color;
                box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
                padding: 25px;
                width: 260px;
                height: calc(100vh - 201px);

                .email-read-menu {
                    .btn {
                        border: none;
                        padding: 12px 20px 11px;
                        position: relative;
                        transition: $transition;

                        font: {
                            size: $font-size;
                            weight: 600;
                        }

                        ;

                        i {
                            font-size: 20px;
                            position: absolute;

                            margin: {
                                left: -23px;
                            }

                            ;
                        }
                    }

                    .btn-primary {
                        color: $white-color;
                        background-color: $main-color;

                        &:not(:disabled):not(.disabled).active,
                        &:not(:disabled):not(.disabled):active,
                        .show>&.dropdown-toggle {
                            background-color: $main-color;
                            color: $white-color;
                            box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, .2);
                        }

                        &:not(:disabled):not(.disabled).active:focus,
                        &:not(:disabled):not(.disabled):active:focus,
                        .show>&.dropdown-toggle:focus {
                            box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, .2);
                        }

                        &:hover,
                        &:focus {
                            background-color: $main-color;
                            color: $white-color;
                            box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, .2);
                        }
                    }

                    .list-group-messages {
                        .list-group-item {
                            text-decoration: none;

                            padding: {
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 29px;
                            }

                            ;
                            margin-bottom: 16px;
                            background-color: $white-color;
                            border: none !important;
                            color: $black-color;
                            border-radius: 0 !important;

                            font: {
                                size: 16px;
                                weight: 600;
                            }

                            ;

                            i {
                                position: absolute;
                                left: 0;
                                transition: $transition;
                                top: 0;
                                font-size: 22px;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }

                            &.active {
                                background-color: $white-color;
                                color: $main-color;
                            }

                            .badge {
                                transition: $transition;
                                margin-left: 4px;
                                position: relative;
                                top: -1px;
                                padding: 4px 6px 4px 6px;
                            }

                            &:hover {
                                color: $main-color;

                                i {
                                    transform: rotateY(-180deg);
                                    color: $main-color;
                                }
                            }
                        }
                    }

                    .list-group-label {
                        letter-spacing: 1px;
                        color: #828D99;

                        margin: {
                            top: 25px;
                            bottom: 15px;
                        }

                        ;

                        font: {
                            size: 14px;
                            weight: 600;
                        }

                        ;
                    }

                    .list-group-labels {
                        .list-group-item {
                            text-decoration: none;

                            padding: {
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 18px;
                            }

                            ;
                            margin-bottom: 12px;
                            background-color: $white-color;
                            border: none !important;
                            color: $black-color;
                            border-radius: 0 !important;

                            font: {
                                size: 16px;
                                weight: 600;
                            }

                            ;

                            &::before {
                                width: 10px;
                                height: 10px;
                                border-radius: 50%;
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 6px;
                                border: 2px solid $main-color;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }

                            &.active {
                                background-color: $white-color;
                                color: $main-color;
                            }

                            &:hover {
                                color: $main-color;
                            }

                            &:nth-child(2) {
                                &::before {
                                    border-color: #f79921;
                                }
                            }

                            &:nth-child(3) {
                                &::before {
                                    border-color: #1ebe40;
                                }
                            }

                            &:nth-child(4) {
                                &::before {
                                    border-color: #40fefe;
                                }
                            }

                            &:nth-child(5) {
                                &::before {
                                    border-color: #707070;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .content-right {
        float: right;
        width: calc(100% - 290px);

        .email-read-list-wrapper {
            .email-read-list {
                background-color: $white-color;
                box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);

                .email-read-list-header {
                    padding: 15px 20px;
                    border-bottom: 1px solid #e8ebf1;

                    .header-left {
                        position: relative;
                        top: 1px;

                        .go-back {
                            color: #727E8C;
                            font-size: 25px;
                            line-height: 0;
                            position: relative;
                            top: -1px;

                            &:hover {
                                color: $main-color;
                            }
                        }

                        h3 {
                            font-size: 18px;

                            .badge {
                                color: $white-color;
                                border-radius: 30px;
                                padding: 5px 15px;

                                font: {
                                    weight: 500;
                                }

                                ;
                            }
                        }
                    }

                    .header-right {
                        position: relative;
                        top: 3px;

                        .btn-icon {
                            width: 34px;
                            height: 34px;
                            background-color: #f3f3f3;
                            transition: $transition;
                            position: relative;
                            border: none;
                            padding: 0;
                            font-size: 18px;
                            border-radius: 3px;

                            i {
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 47%;
                                transform: translateY(-47%);
                            }

                            &:hover {
                                color: $white-color;
                                background-color: $main-color;
                            }

                            &::after {
                                display: none;
                            }
                        }

                        .dropdown {
                            .dropdown-menu {
                                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .05);
                                background-color: $white-color;
                                border: none;
                                border-radius: 0;
                                margin-top: 35px;
                                display: block;
                                opacity: 0;
                                visibility: hidden;
                                transition: .3s;
                                transform: unset !important;

                                &.show {
                                    margin-top: 45px;
                                    opacity: 1;
                                    visibility: visible;
                                }

                                .dropdown-item {
                                    color: $black-color;
                                    padding: 5px 15px 5px 36px;
                                    position: relative;

                                    font: {
                                        size: 14px;
                                    }

                                    ;

                                    i {
                                        color: #686868;
                                        transition: $transition;
                                        position: absolute;
                                        left: 15px;
                                        top: 7px;

                                        font: {
                                            size: 16px;
                                        }

                                        ;
                                    }

                                    &.active,
                                    &:active {
                                        color: $black-color;
                                        background-color: transparent;
                                        background-color: #f8f9fa;
                                    }

                                    &:hover {
                                        i {
                                            transform: rotateY(-180deg);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .email-read-scroll-area {
                    height: calc(100vh - 338px);
                }

                .list-wrapper {
                    padding: 20px;
                    margin-bottom: 0;
                    list-style-type: none;

                    .email-read-list-item {
                        border-radius: 5px;
                        border: 1px solid $white-color;
                        padding: 15px 20px;
                        width: 100%;
                        position: relative;
                        font-size: $font-size;
                        transition: $transition;
                        margin-bottom: 15px;
                        box-shadow: 0 0 18px rgba(25, 42, 70, .13);
                        background-color: $white-color;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .email-read-list-detail {
                            text-decoration: none;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-grow: 1;
                            flex-wrap: wrap;
                            cursor: pointer;
                            position: relative;

                            .user-information {
                                position: relative;
                                padding-left: 65px;
                                margin-top: -2px;
                            }

                            img {
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 50px;
                                height: 50px;
                                border-radius: 50%;
                            }

                            .name {
                                color: $black-color;

                                font: {
                                    size: 16px;
                                    weight: 600;
                                }

                                ;

                                .email {
                                    color: #727E8C;

                                    font: {
                                        weight: normal;
                                        size: $font-size;
                                    }

                                    ;
                                }
                            }

                            .to {
                                color: #71738d;
                                font-size: 13px;
                            }

                            .email-information {
                                .date {
                                    color: #727E8C;
                                    font-weight: 500;
                                }

                                .favorite {
                                    display: inline-block;
                                    font-size: 20px;
                                    cursor: pointer;
                                    color: #C7CFD6;

                                    margin: {
                                        left: 12px;
                                        right: 8px;
                                    }

                                    ;

                                    &.active {
                                        color: #FDAC41;

                                        i {
                                            &::before {
                                                content: "\ed99";
                                            }
                                        }
                                    }
                                }

                                .dropdown {
                                    .dropdown-toggle {
                                        padding: 0;
                                        border: none;
                                        background-color: transparent;
                                        color: #727E8C;
                                        font-size: 22px;
                                        line-height: 15px;

                                        &::after {
                                            display: none;
                                        }
                                    }

                                    .dropdown-menu {
                                        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .05);
                                        background-color: $white-color;
                                        border: none;
                                        border-radius: 0;
                                        margin-top: 35px;
                                        display: block;
                                        opacity: 0;
                                        visibility: hidden;
                                        transition: .3s;
                                        transform: unset !important;
                                        left: auto !important;
                                        right: 0;

                                        &.show {
                                            margin-top: 25px;
                                            opacity: 1;
                                            visibility: visible;
                                        }

                                        .dropdown-item {
                                            color: $black-color;
                                            padding: 5px 15px 5px 36px;
                                            position: relative;

                                            font: {
                                                size: 14px;
                                            }

                                            ;

                                            i {
                                                color: #686868;
                                                transition: $transition;
                                                position: absolute;
                                                left: 15px;
                                                top: 7px;

                                                font: {
                                                    size: 16px;
                                                }

                                                ;
                                            }

                                            &.active,
                                            &:active {
                                                color: $black-color;
                                                background-color: transparent;
                                                background-color: #f8f9fa;
                                            }

                                            &:hover {
                                                i {
                                                    transform: rotateY(-180deg);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .email-read-list-content {
                            margin-top: 25px;

                            p {
                                margin-bottom: 10px;
                                color: #727E8C;
                            }

                            .email-read-list-footer {
                                border-top: 1px solid #e8ebf1;

                                padding: {
                                    top: 18px;
                                    left: 20px;
                                    right: 20px;
                                }

                                ;

                                margin: {
                                    left: -20px;
                                    top: 20px;
                                    right: -20px;
                                }

                                ;

                                label {
                                    font-size: 14px;
                                }

                                ul {
                                    li {
                                        margin-bottom: 5px;

                                        a {
                                            color: #727E8C;
                                            text-decoration: none;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .email-reply-list-item {
                        margin: {
                            top: 30px;
                            bottom: 8px;
                        }

                        ;

                        a {
                            box-shadow: 0 0 18px rgba(25, 42, 70, 0.13);
                            background-color: $white-color;
                            border: 1px solid #eeeeee;
                            padding: 10px 30px;
                            border-radius: 30px;
                            margin-right: 10px;
                            text-decoration: none;
                            color: $black-color;

                            font: {
                                size: $font-size;
                                weight: 600;
                            }

                            ;

                            &:last-child {
                                margin-right: 0;
                            }

                            i {
                                font-size: 20px;
                                position: relative;
                                top: 3px;
                            }

                            &:hover {
                                color: $white-color;
                                border-color: $main-color;
                                background-color: $main-color;
                            }
                        }
                    }
                }

                .email-list-footer {
                    border-top: 1px solid #e8ebf1;
                    padding: 15px 20px;

                    p {
                        color: #71738d;
                    }

                    .email-pagination-btn {
                        margin-bottom: -5px;

                        button {
                            width: 34px;
                            height: 34px;
                            background-color: #f3f3f3;
                            transition: $transition;
                            position: relative;
                            border: none;
                            padding: 0;
                            font-size: 18px;
                            border-radius: 5px;
                            margin-left: 5px;

                            i {
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }

                            &:hover {
                                color: $white-color;
                                background-color: $main-color;
                            }

                            &.email-pagination-prev {
                                border-radius: 5px 0 0 5px;
                            }

                            &.email-pagination-next {
                                border-radius: 0 5px 5px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*================================================
App Email Compose CSS
=================================================*/
.email-compose-content-area {
    margin-bottom: 30px;

    .sidebar-left {
        float: left;

        .sidebar {
            .sidebar-content {
                background-color: $white-color;
                box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
                padding: 25px;
                width: 260px;

                .email-compose-menu {
                    .list-group-messages {
                        .list-group-item {
                            text-decoration: none;

                            padding: {
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 29px;
                            }

                            ;
                            margin-bottom: 16px;
                            background-color: $white-color;
                            border: none !important;
                            color: $black-color;
                            border-radius: 0 !important;

                            font: {
                                size: 16px;
                                weight: 600;
                            }

                            ;

                            i {
                                position: absolute;
                                left: 0;
                                transition: $transition;
                                top: 0;
                                font-size: 22px;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }

                            &.active {
                                background-color: $white-color;
                                color: $main-color;
                            }

                            .badge {
                                transition: $transition;
                                margin-left: 4px;
                                position: relative;
                                top: -1px;
                                padding: 4px 6px 4px 6px;
                            }

                            &:hover {
                                color: $main-color;

                                i {
                                    transform: rotateY(-180deg);
                                    color: $main-color;
                                }
                            }
                        }
                    }

                    .list-group-label {
                        letter-spacing: 1px;
                        color: #828D99;

                        margin: {
                            top: 25px;
                            bottom: 15px;
                        }

                        ;

                        font: {
                            size: 14px;
                            weight: 600;
                        }

                        ;
                    }

                    .list-group-labels {
                        .list-group-item {
                            text-decoration: none;

                            padding: {
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 18px;
                            }

                            ;
                            margin-bottom: 12px;
                            background-color: $white-color;
                            border: none !important;
                            color: $black-color;
                            border-radius: 0 !important;

                            font: {
                                size: 16px;
                                weight: 600;
                            }

                            ;

                            &::before {
                                width: 10px;
                                height: 10px;
                                border-radius: 50%;
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 6px;
                                border: 2px solid $main-color;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }

                            &.active {
                                background-color: $white-color;
                                color: $main-color;
                            }

                            &:hover {
                                color: $main-color;
                            }

                            &:nth-child(2) {
                                &::before {
                                    border-color: #f79921;
                                }
                            }

                            &:nth-child(3) {
                                &::before {
                                    border-color: #1ebe40;
                                }
                            }

                            &:nth-child(4) {
                                &::before {
                                    border-color: #40fefe;
                                }
                            }

                            &:nth-child(5) {
                                &::before {
                                    border-color: #707070;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .content-right {
        float: right;
        width: calc(100% - 290px);

        .email-compose-list-wrapper {
            background-color: $white-color;
            box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
            border: none;
            border-radius: 5px;
            padding: 25px;

            h3 {
                margin-bottom: 20px;

                font: {
                    size: 18px;
                    weight: 600;
                }

                ;
            }

            form {
                .card {
                    background-color: transparent;
                    box-shadow: unset;
                    border-radius: 5px;
                    padding: 0;

                    .card-header {
                        background-color: transparent;
                        margin-bottom: 0;
                    }
                }

                .form-control {
                    height: 42px;

                    &:focus {
                        border-color: #b1bbc4;
                    }
                }

                .note-editor.note-airframe,
                .note-editor.note-frame {
                    border-color: #ced4da;
                }

                .note-popover .popover-content>.note-btn-group,
                .note-toolbar>.note-btn-group {
                    margin-top: -1px;
                }

                .form-group {
                    .btn {
                        transition: $transition;
                        border-radius: 2px;
                        box-shadow: unset !important;
                        padding: 10px 25px 9.5px;

                        font: {
                            size: $font-size;
                            weight: 600;
                        }

                        ;

                        i {
                            position: relative;
                            top: 1px;
                        }

                        &.btn-success {
                            background-color: #3578e5;
                            color: $white-color;
                            border-color: #3578e5;

                            &:hover,
                            &:focus {
                                background-color: #3578e5;
                                color: $white-color;
                                border-color: #3578e5;
                                box-shadow: 1px 5px 24px 0 rgba(102, 88, 221, 0.25) !important;
                            }
                        }

                        &.btn-primary {
                            background-color: $main-color;
                            color: $white-color;
                            border-color: $main-color;

                            &:hover,
                            &:focus {
                                background-color: #e33e45;
                                color: $white-color;
                                border-color: #e33e45;
                                box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, 0.25) !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*================================================
App Todo CSS
=================================================*/
.todo-content-area {
    margin-bottom: 30px;

    .sidebar-left {
        float: left;

        .sidebar {
            .sidebar-content {
                background-color: $white-color;
                box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
                padding: 25px;
                width: 260px;
                height: calc(100vh - 201px);

                .todo-menu {
                    .btn {
                        border: none;
                        padding: 12px 20px 11px;
                        position: relative;
                        transition: $transition;

                        font: {
                            size: $font-size;
                            weight: 600;
                        }

                        ;

                        i {
                            font-size: 20px;
                            position: absolute;

                            margin: {
                                left: -23px;
                            }

                            ;
                        }
                    }

                    .btn-primary {
                        color: $white-color;
                        background-color: $main-color;

                        &:not(:disabled):not(.disabled).active,
                        &:not(:disabled):not(.disabled):active,
                        .show>&.dropdown-toggle {
                            background-color: $main-color;
                            color: $white-color;
                            box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, .2);
                        }

                        &:not(:disabled):not(.disabled).active:focus,
                        &:not(:disabled):not(.disabled):active:focus,
                        .show>&.dropdown-toggle:focus {
                            box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, .2);
                        }

                        &:hover,
                        &:focus {
                            background-color: $main-color;
                            color: $white-color;
                            box-shadow: 1px 5px 24px 0 rgba(255, 0, 10, .2);
                        }
                    }

                    .list-group-messages {
                        .list-group-item {
                            padding: {
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 29px;
                            }

                            ;
                            text-decoration: none;
                            margin-bottom: 16px;
                            background-color: $white-color;
                            border: none !important;
                            color: $black-color;
                            border-radius: 0 !important;

                            font: {
                                size: 16px;
                                weight: 600;
                            }

                            ;

                            i {
                                position: absolute;
                                left: 0;
                                transition: $transition;
                                top: 0;
                                font-size: 22px;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }

                            &.active {
                                background-color: $white-color;
                                color: $main-color;
                            }

                            .badge {
                                transition: $transition;
                                margin-left: 4px;
                                position: relative;
                                top: -1px;
                                padding: 4px 6px 4px 6px;
                            }

                            &:hover {
                                color: $main-color;

                                i {
                                    transform: rotateY(-180deg);
                                    color: $main-color;
                                }
                            }
                        }
                    }

                    .list-group-label {
                        letter-spacing: 1px;
                        color: #828D99;

                        margin: {
                            top: 25px;
                            bottom: 15px;
                        }

                        ;

                        font: {
                            size: 14px;
                            weight: 600;
                        }

                        ;
                    }

                    .list-group-labels {
                        .list-group-item {
                            text-decoration: none;

                            padding: {
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 18px;
                            }

                            ;
                            margin-bottom: 12px;
                            background-color: $white-color;
                            border: none !important;
                            color: $black-color;
                            border-radius: 0 !important;

                            font: {
                                size: 16px;
                                weight: 600;
                            }

                            ;

                            &::before {
                                width: 10px;
                                height: 10px;
                                border-radius: 50%;
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 6px;
                                border: 2px solid $main-color;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }

                            &.active {
                                background-color: $white-color;
                                color: $main-color;
                            }

                            &:hover {
                                color: $main-color;
                            }

                            &:nth-child(2) {
                                &::before {
                                    border-color: #f79921;
                                }
                            }

                            &:nth-child(3) {
                                &::before {
                                    border-color: #1ebe40;
                                }
                            }

                            &:nth-child(4) {
                                &::before {
                                    border-color: #40fefe;
                                }
                            }

                            &:nth-child(5) {
                                &::before {
                                    border-color: #707070;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .content-right {
        float: right;
        width: calc(100% - 290px);

        .todo-list-wrapper {
            .todo-list {
                background-color: $white-color;
                box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);

                .todo-list-header {
                    padding: 15px 20px;
                    border-bottom: 1px solid #e8ebf1;

                    .header-left {
                        position: relative;
                        top: 3px;

                        .checkbox {
                            position: relative;
                            top: -2px;
                        }

                        .btn-icon {
                            width: 34px;
                            height: 34px;
                            background-color: #f3f3f3;
                            transition: $transition;
                            position: relative;
                            border: none;
                            padding: 0;
                            font-size: 18px;
                            border-radius: 3px;

                            i {
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 47%;
                                transform: translateY(-47%);
                            }

                            &:hover {
                                color: $white-color;
                                background-color: $main-color;
                            }

                            &::after {
                                display: none;
                            }
                        }

                        .dropdown {
                            .dropdown-menu {
                                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .05);
                                background-color: $white-color;
                                border: none;
                                border-radius: 0;
                                margin-top: 35px;
                                display: block;
                                opacity: 0;
                                visibility: hidden;
                                transition: .3s;
                                transform: unset !important;

                                &.show {
                                    margin-top: 45px;
                                    opacity: 1;
                                    visibility: visible;
                                }

                                .dropdown-item {
                                    color: $black-color;
                                    padding: 5px 15px 5px 36px;
                                    position: relative;

                                    font: {
                                        size: 14px;
                                    }

                                    ;

                                    i {
                                        color: #686868;
                                        transition: $transition;
                                        position: absolute;
                                        left: 15px;
                                        top: 7px;

                                        font: {
                                            size: 16px;
                                        }

                                        ;
                                    }

                                    &.active,
                                    &:active {
                                        color: $black-color;
                                        background-color: transparent;
                                        background-color: #f8f9fa;
                                    }

                                    &:hover {
                                        i {
                                            transform: rotateY(-180deg);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .header-right {
                        position: relative;

                        label {
                            position: absolute;
                            left: 15px;
                            color: #71738d;
                            margin-bottom: 0;
                            top: 14px;
                        }

                        .form-control {
                            height: 45px;
                            border-radius: 30px;
                            background-color: #eef5f9;
                            border: none;
                            color: $black-color;
                            padding: 2px 15px 0 39px;
                            transition: $transition;
                            box-shadow: unset;

                            font: {
                                size: 14px;
                                weight: 400;
                            }

                            ;

                            &:focus {
                                box-shadow: unset;
                                background-color: #eef5f9;

                                &::placeholder {
                                    color: transparent;
                                }
                            }

                            &::placeholder {
                                color: #a8a8a8;
                                transition: $transition;
                            }
                        }
                    }
                }

                .list-wrapper {
                    height: calc(100vh - 277px);
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style-type: none;

                    .todo-list-item {
                        position: relative;
                        transition: $transition;
                        border-bottom: 1px solid #e8ebf1;

                        padding: {
                            left: 60px;
                            right: 110px;
                            top: 15px;
                            bottom: 15px;
                        }

                        ;

                        .checkbox {
                            position: absolute;
                            left: 25px;
                            top: 50%;
                            transform: translateY(-50%);
                        }

                        .todo-item-title {
                            position: relative;
                            padding-left: 53px;

                            img {
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                position: absolute;
                                cursor: pointer;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                            }

                            h3 {
                                margin-bottom: 2px;

                                font: {
                                    size: 16px;
                                    weight: 600;
                                }

                                ;
                            }

                            p {
                                color: #919191;
                                font-size: $font-size;
                                margin-bottom: 0;
                            }
                        }

                        .todo-item-action {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 25px;

                            a {
                                display: inline-block;
                                width: 30px;
                                height: 30px;
                                line-height: 32px;
                                border-radius: 3px;
                                background-color: #c6e8cd;
                                color: #13bb37;
                                text-align: center;
                                margin-left: 3px;

                                &:hover {
                                    background-color: #13bb37;
                                    color: $white-color;
                                }

                                &.delete {
                                    background-color: #f9d0d9 !important;
                                    color: #e1000a !important;

                                    &:hover {
                                        background-color: #e1000a !important;
                                        color: $white-color !important;
                                    }
                                }
                            }
                        }

                        &:hover {
                            box-shadow: 0 0 10px 0 rgba(58, 70, 93, .25);
                        }

                        &:nth-child(2),
                        &:nth-child(6),
                        &:nth-child(10) {
                            .todo-item-action {
                                a {
                                    background-color: #d1def5;
                                    color: #4788ff;

                                    &:hover {
                                        background-color: #4788ff;
                                        color: $white-color;
                                    }
                                }
                            }
                        }

                        &:nth-child(3),
                        &:nth-child(7),
                        &:nth-child(11) {
                            .todo-item-action {
                                a {
                                    background-color: #f5d1c4;
                                    color: #fb4a06;

                                    &:hover {
                                        background-color: #fb4a06;
                                        color: $white-color;
                                    }
                                }
                            }
                        }

                        &:nth-child(4),
                        &:nth-child(8),
                        &:nth-child(12) {
                            .todo-item-action {
                                a {
                                    background-color: #d1def5;
                                    color: #4788ff;

                                    &:hover {
                                        background-color: #4788ff;
                                        color: $white-color;
                                    }
                                }
                            }
                        }

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
}

/*================================================
App Calendar CSS
=================================================*/
.calendar-container {
    overflow: hidden;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    border-radius: 5px;
    background: $white-color;

    .calendar {
        display: grid;
        width: 100%;
        overflow: auto;

        grid: {
            template-columns: repeat(7, minmax(120px, 1fr));
            template-rows: 50px;
            auto-rows: 120px;
        }

        ;
    }

    .calendar-header {
        position: relative;
        padding: 25px 20px;
        border-bottom: 1px solid rgba(166, 168, 179, 0.12);

        h3 {
            font: {
                size: 18px;
                weight: 600;
            }

            ;
        }

        button {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            border: none;
            background-color: $main-color;
            color: $white-color;
            transition: $transition;
            border-radius: 30px;
            padding: 10px 30px;

            font: {
                size: $font-size;
                weight: 600;
            }

            ;

            &:hover {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }

    .day {
        border-bottom: 1px solid rgba(166, 168, 179, 0.12);
        border-right: 1px solid rgba(166, 168, 179, 0.12);
        text-align: right;
        padding: 14px 20px;
        letter-spacing: 1px;
        font-size: 12px;
        box-sizing: border-box;
        color: #898989;
        position: relative;
        pointer-events: none;
        z-index: 1;

        &:nth-of-type(7n + 7) {
            border-right: 0;
        }

        &:nth-of-type(n + 1):nth-of-type(-n + 7) {
            grid-row: 2;
        }

        &:nth-of-type(n + 8):nth-of-type(-n + 14) {
            grid-row: 3;
        }

        &:nth-of-type(n + 15):nth-of-type(-n + 21) {
            grid-row: 4;
        }

        &:nth-of-type(n + 22):nth-of-type(-n + 28) {
            grid-row: 5;
        }

        &:nth-of-type(n + 29):nth-of-type(-n + 35) {
            grid-row: 6;
        }

        &:nth-of-type(7n + 1) {
            grid-column: 1/1;
        }

        &:nth-of-type(7n + 2) {
            grid-column: 2/2;
        }

        &:nth-of-type(7n + 3) {
            grid-column: 3/3;
        }

        &:nth-of-type(7n + 4) {
            grid-column: 4/4;
        }

        &:nth-of-type(7n + 5) {
            grid-column: 5/5;
        }

        &:nth-of-type(7n + 6) {
            grid-column: 6/6;
        }

        &:nth-of-type(7n + 7) {
            grid-column: 7/7;
        }
    }

    .day-name {
        color: $black-color;
        border-bottom: 1px solid rgba(166, 168, 179, 0.12);
        line-height: 50px;

        text: {
            transform: uppercase;
            align: center;
        }

        ;

        font: {
            size: 12px;
            weight: 600;
        }

        ;
    }

    .day--disabled {
        background-color: $white-color;
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f9f9fa' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
        cursor: not-allowed;
    }

    .task {
        border-left-width: 3px;
        padding: 8px 12px;
        margin: 10px;
        border-left-style: solid;
        font-size: 14px;
        position: relative;

        &.task--warning {
            border-left-color: #fdb44d;
            grid-column: 4 / span 3;
            grid-row: 3;
            background: #fef0db;
            align-self: center;
            color: #fc9b10;
            margin-top: -5px;
        }

        &.task--danger {
            border-left-color: #fa607e;
            grid-column: 2 / span 3;
            grid-row: 3;
            margin-top: 15px;
            background: rgba(253, 197, 208, 0.7);
            align-self: end;
            color: #f8254e;
        }

        &.task--info {
            border-left-color: #4786ff;
            grid-column: 6 / span 2;
            grid-row: 5;
            margin-top: 15px;
            background: rgba(218, 231, 255, 0.7);
            align-self: end;
            color: #0a5eff;
        }

        &.task--primary {
            background: #4786ff;
            border: 0;
            border-radius: 4px;
            grid-column: 3 / span 3;
            grid-row: 4;
            align-self: end;
            color: #fff;
            box-shadow: 0 10px 14px rgba(71, 134, 255, 0.4);
        }

        .task__detail {
            position: absolute;
            left: 0;
            top: calc(100% + 10px);
            background: $white-color;
            border: 1px solid rgba(166, 168, 179, 0.2);
            color: #000000;
            padding: 20px;
            border-radius: 4px;
            box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
            z-index: 2;

            &::after,
            &::before {
                bottom: 100%;
                left: 30%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
            }

            &::before {
                margin-left: -8px;

                border: {
                    bottom-color: rgba(166, 168, 179, 0.2);
                    width: 8px;
                }

                ;
            }

            &:after {
                margin-left: -6px;

                border: {
                    bottom-color: $white-color;
                    width: 6px;
                }

                ;
            }

            h4 {
                font-size: 15px;
                margin: 0;
                color: #51565d;
            }

            p {
                margin-top: 4px;
                margin-bottom: 0;
                color: rgba(81, 86, 93, 0.7);

                font: {
                    weight: 500;
                    size: 12px;
                }

                ;
            }
        }
    }
}

/*================================================
App Chat CSS
=================================================*/
.chat-content-area {
    margin-bottom: 30px;

    .sidebar-left {
        float: left;

        .sidebar {
            background-color: $white-color;
            box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
            padding: 25px;
            width: 300px;

            .chat-sidebar-header {
                padding-bottom: 25px;

                form {
                    label {
                        display: block;
                        margin-bottom: 0;
                        position: absolute;
                        left: 15px;
                        top: 11.3px;
                        font-size: 18px;
                        color: #a8a8a8;
                    }

                    .form-control {
                        height: 45px;
                        border-radius: 30px;
                        background-color: #eef5f9;
                        border: none;
                        color: $black-color;
                        padding: 0 15px 0 40px;
                        transition: $transition;
                        box-shadow: unset;

                        font: {
                            size: 14px;
                            weight: 400;
                        }

                        ;

                        &:focus {
                            box-shadow: unset;
                            background-color: #eef5f9;

                            &::placeholder {
                                color: transparent !important;
                            }
                        }

                        &::placeholder {
                            color: #a8a8a8;
                            transition: $transition;
                        }
                    }
                }
            }

            .sidebar-content {
                height: calc(100vh - 321px);

                .chat-menu {
                    .list-group-user {
                        li {
                            cursor: pointer;
                            margin-bottom: 20px;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            .avatar {
                                position: relative;

                                .status-busy {
                                    background-color: #FF5B5C;
                                }

                                .status-online {
                                    background-color: #39DA8A;
                                }

                                .status-away {
                                    background-color: #FDAC41;
                                }

                                .status-offline {
                                    background-color: #475F7B;
                                }

                                [class*=status-] {
                                    border-radius: 50%;
                                    width: 10px;
                                    height: 10px;
                                    position: absolute;
                                    left: 1px;
                                    top: 1px;
                                }
                            }

                            h6 {
                                margin-bottom: 3px;

                                font: {
                                    size: 16px;
                                }

                                ;
                            }

                            span {
                                color: #71738d;
                                font-size: 14.5px;
                            }
                        }
                    }

                    .list-group-label {
                        color: $black-color;

                        margin: {
                            top: 25px;
                            bottom: 20px;
                        }

                        ;
                        padding-bottom: 5px;
                        position: relative;
                        border-bottom: 1px solid #eeeeee;

                        font: {
                            size: 18px;
                            weight: 600;
                        }

                        ;

                        &::before {
                            width: 70px;
                            height: 1px;
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: -1px;
                            background-color: $main-color;
                        }
                    }
                }
            }
        }
    }

    .content-right {
        float: right;
        width: calc(100% - 330px);

        .chat-list-wrapper {
            .chat-list {
                background-color: $white-color;
                box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);

                .simplebar-scrollbar {
                    &::before {
                        background: #aaaaaa;
                    }
                }

                .chat-list-header {
                    padding: 15px 20px;

                    .header-left {
                        h6 {
                            white-space: nowrap;
                            font-size: 16px;
                        }

                        .avatar {
                            position: relative;

                            .status-busy {
                                background-color: #FF5B5C;
                            }

                            .status-online {
                                background-color: #39DA8A;
                            }

                            .status-away {
                                background-color: #FDAC41;
                            }

                            .status-offline {
                                background-color: #475F7B;
                            }

                            [class*=status-] {
                                border-radius: 50%;
                                width: 10px;
                                height: 10px;
                                position: absolute;
                                left: 1px;
                                top: 1px;
                            }
                        }
                    }

                    .header-right {
                        position: relative;
                        top: 1px;

                        ul {
                            li {
                                display: inline-block;
                                margin-left: 1px;

                                &:first-child {
                                    margin-left: 0;
                                }

                                .favorite {
                                    font-size: 20px;
                                    cursor: pointer;
                                    color: #C7CFD6;
                                    position: relative;
                                    top: -1px;

                                    &.active {
                                        color: #FDAC41;

                                        i {
                                            &::before {
                                                content: "\ed99";
                                            }
                                        }
                                    }
                                }

                                .dropdown {
                                    .dropdown-toggle {
                                        padding: 0;
                                        border: none;
                                        background-color: transparent;
                                        color: #727E8C;
                                        font-size: 22px;
                                        line-height: 15px;

                                        &::after {
                                            display: none;
                                        }
                                    }

                                    .dropdown-menu {
                                        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .05);
                                        background-color: $white-color;
                                        border: none;
                                        border-radius: 0;
                                        margin-top: 35px;
                                        display: block;
                                        opacity: 0;
                                        visibility: hidden;
                                        transition: .3s;
                                        transform: unset !important;
                                        left: auto !important;
                                        right: 0;

                                        &.show {
                                            margin-top: 25px;
                                            opacity: 1;
                                            visibility: visible;
                                        }

                                        .dropdown-item {
                                            color: $black-color;
                                            padding: 5px 15px 5px 36px;
                                            position: relative;

                                            font: {
                                                size: 14px;
                                            }

                                            ;

                                            i {
                                                color: #686868;
                                                transition: $transition;
                                                position: absolute;
                                                left: 15px;
                                                top: 7px;

                                                font: {
                                                    size: 16px;
                                                }

                                                ;
                                            }

                                            &.active,
                                            &:active {
                                                color: $black-color;
                                                background-color: transparent;
                                                background-color: #f8f9fa;
                                            }

                                            &:hover {
                                                i {
                                                    transform: rotateY(-180deg);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .chat-container {
                    height: calc(100vh - 338px);
                    background-color: #E3E6F1;
                    padding: 25px 20px;

                    background: {
                        image: url(../../assets/img/chat-bg.png);
                        position: center center;
                        size: contain;
                        repeat: repeat;
                    }

                    ;

                    .chat-content {
                        text-align: center;

                        .chat {
                            .chat-avatar {
                                float: right;
                            }

                            .chat-body {
                                overflow: hidden;

                                .chat-message {
                                    position: relative;
                                    float: right;
                                    max-width: 420px;
                                    text-align: left;
                                    padding: 12px 16px;
                                    margin: 10px 21.28px 21.28px 0;
                                    clear: both;
                                    word-break: break-word;
                                    color: $white-color;
                                    background-color: #3578e5;
                                    border-radius: 5px;
                                    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .3);

                                    p {
                                        color: $white-color;
                                        margin-bottom: 0;

                                        a {
                                            color: $white-color !important;
                                        }
                                    }

                                    .time {
                                        position: absolute;
                                        bottom: -25px;
                                        right: 0;
                                        color: #6b7886;
                                        font-size: .8rem;
                                        white-space: nowrap;
                                    }

                                    &:first-child {
                                        margin-top: 0 !important;
                                    }
                                }
                            }

                            &.chat-left {
                                .chat-avatar {
                                    float: left;
                                }

                                .chat-body {
                                    .chat-message {
                                        text-align: left;
                                        float: left;
                                        margin: 10px 0 21.28px 21.28px;
                                        color: #727E8C;
                                        background-color: $white-color;
                                        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, .3);

                                        p {
                                            color: #727E8C;

                                            a {
                                                color: #727E8C !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .bg-light {
                            padding: 9px 20px 7px;
                            text-transform: uppercase;
                        }
                    }
                }

                .chat-list-footer {
                    background-color: #fafafa;
                    padding: 15px 20px;

                    .emoji-btn {
                        padding: 0;
                        border: none;
                        background-color: #f9d0d9;
                        border-radius: 50%;
                        width: 35px;
                        font-size: 19px;
                        line-height: 40px;
                        height: 35px;
                        color: $main-color;
                        transition: $transition;

                        &:hover,
                        &:focus {
                            background-color: $main-color;
                            color: $white-color;
                        }
                    }

                    .file-attachment-btn {
                        padding: 0;
                        border: none;
                        background-color: #f9d0d9;
                        border-radius: 50%;
                        width: 35px;
                        font-size: 19px;
                        line-height: 40px;
                        height: 35px;
                        color: $main-color;
                        transition: $transition;

                        &:hover,
                        &:focus {
                            background-color: $main-color;
                            color: $white-color;
                        }
                    }

                    form {
                        position: relative;
                        padding-right: 128px;

                        .form-control {
                            background-color: $white-color;
                            height: 45px;
                            border-radius: 30px;
                            transition: $transition;
                            padding-left: 15px;
                            font-size: 14.5px;
                            border: 1px solid #DFE3E7;
                            color: $black-color;

                            &:focus {
                                border-color: $main-color;
                                box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .1);
                            }
                        }

                        .send-btn {
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            border: none;
                            background-color: $main-color;
                            color: $white-color;
                            transition: $transition;
                            border-radius: 30px;
                            height: 45px;
                            padding: 0 30px;
                            box-shadow: 0 4px 12px 0 rgba(255, 0, 10, .2);

                            font: {
                                size: $font-size;
                                weight: 600;
                            }

                            ;

                            &:hover {
                                box-shadow: 0 4px 12px 0 rgba(255, 0, 10, .6);
                            }
                        }
                    }
                }
            }
        }
    }

    .bg-light {
        color: #212529;
        background-color: #f8f9fa;
    }
}

.mr-2,
.mx-2 {
    margin-right: .5rem !important;
}

/*================================================
Profile CSS
=================================================*/
.profile-header {
    margin-top: 20px;
    border-radius: 5px;
    position: relative;

    .user-profile-images {
        position: relative;

        .cover-image {
            border-radius: 5px;
        }

        .profile-image {
            position: absolute;
            left: 25px;
            bottom: -70px;

            img {
                border-radius: 50%;
                width: 170px;
                height: 170px;
                border: 5px solid $white-color;
                box-shadow: 0 0 20px 0 rgba(0, 0, 0, .41);
            }

            .upload-profile-photo {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 20px;
                opacity: 0;
                visibility: hidden;
                transition: $transition;
                z-index: 1;

                a {
                    background-color: transparent;
                    color: $white-color;
                    text-decoration: none;
                    transition: $transition;
                    text-align: center;
                    display: inline-block;

                    font: {
                        size: $font-size;
                        weight: 600;
                    }

                    ;

                    i {
                        font-size: 25px;
                        color: $white-color;
                        margin-bottom: 2px;
                        display: block;
                    }
                }
            }

            &:hover {
                .upload-profile-photo {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .upload-cover-photo {
            display: inline-block;
            position: absolute;
            left: 20px;
            top: 20px;
            z-index: 1;

            a {
                background-color: transparent;
                color: $white-color;
                text-decoration: none;
                transition: $transition;

                font: {
                    size: $font-size;
                    weight: 600;
                }

                ;

                i {
                    font-size: 25px;
                    color: $white-color;
                    position: relative;
                    top: 5px;
                    margin-right: 2px;
                }

                span {
                    opacity: 0;
                    visibility: hidden;
                    transition: $transition;
                }
            }
        }

        .user-profile-text {
            position: absolute;
            left: 220px;
            z-index: 1;
            bottom: 15px;

            h3 {
                margin-bottom: 3px;
                color: $white-color;

                font: {
                    size: 25px;
                    weight: 600;
                }

                ;
            }

            span {
                color: $white-color;
                opacity: 0.9;

                font: {
                    size: $font-size;
                    weight: 600;
                }

                ;
            }
        }

        &:hover {
            .upload-cover-photo {
                a {
                    span {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }

    .user-profile-nav {
        padding: 25px 25px 25px 220px;
        background-color: $white-color;
        box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);

        .nav {
            padding-left: 0;
            border: none;
            margin-bottom: 0;
            list-style-type: none;

            .nav-item {
                margin-right: 10px;

                .nav-link {
                    color: #475F7B;
                    border-radius: 5px;
                    transition: $transition;
                    text-decoration: none;
                    background-color: $white-color;
                    background-color: #f7f7f7;

                    font: {
                        size: $font-size;
                        weight: 600;
                    }

                    ;

                    padding: {
                        left: 25px;
                        right: 25px;
                        top: 12px;
                        bottom: 12px;
                    }

                    ;

                    &.active,
                    &:hover {
                        color: $white-color;
                        background-color: $main-color
                    }
                }
            }
        }
    }
}

.user-intro-box {
    .card-body {
        text-align: center;

        button {
            display: block;
            width: 100%;
            border-radius: 5px;
            background-color: #f5f6f7;
            transition: $transition;
            border: 1px solid #ccd0d5;
            color: #4b4f56;
            margin-top: 10px;

            padding: {
                left: 0;
                right: 0;
                top: 5px;
                bottom: 5px;
            }

            ;

            &:hover {
                background-color: $main-color;
                color: $white-color;
                border-color: $main-color;
            }
        }
    }
}

.user-info-box {
    .card-body {
        ul {
            li {
                color: #1d2129;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                span {
                    line-height: 1.6;
                }

                i {
                    font-size: 22px;
                    color: #616770;
                    transition: $transition;
                }

                a {
                    color: #385898;
                    text-decoration: none;
                }

                &:hover {
                    i {
                        transform: rotateY(-180deg);
                        color: $main-color;
                    }
                }
            }
        }

        button {
            display: block;
            width: 100%;
            border-radius: 5px;
            background-color: #f5f6f7;
            transition: $transition;
            border: 1px solid #ccd0d5;
            color: #4b4f56;
            margin-top: 15px;

            padding: {
                left: 0;
                right: 0;
                top: 5px;
                bottom: 5px;
            }

            ;

            &:hover {
                background-color: $main-color;
                color: $white-color;
                border-color: $main-color;
            }
        }
    }
}

.user-photos-box {
    .card-header {
        .photo-upload-btn {
            display: inline-block;
            border: 1px solid #afb8bd;
            color: $black-color;
            border-radius: 5px;
            background-color: transparent;
            text-decoration: none;
            padding: 3px 12px;
            transition: $transition;

            font: {
                size: 14px;
                weight: 600;
            }

            ;

            &:hover {
                background-color: $main-color;
                color: $white-color;
                border-color: $main-color;
            }
        }
    }

    .card-body {
        ul {
            margin: {
                left: -5px;
                right: -5px;
                top: -10px;
            }

            ;

            li {
                flex: 0 0 33.333333%;
                max-width: 33.333333%;

                padding: {
                    left: 5px;
                    right: 5px;
                    top: 10px;
                }

                ;

                a {
                    display: block;
                }
            }
        }
    }
}

.user-skills-box {
    .card-header {
        .create-btn {
            display: inline-block;
            border: none;
            color: #385898;
            background-color: transparent;
            text-decoration: none;
            padding: 0;
            transition: $transition;

            font: {
                size: 14px;
                weight: 600;
            }

            ;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .card-body {
        margin-bottom: -3px;

        .badge {
            background-color: #fde8e8;
            padding: 5px 8px 4px;
            color: $main-color;
            cursor: pointer;
            transition: $transition;

            margin: {
                bottom: 3px;
                right: 1px;
            }

            ;

            &:hover {
                color: $white-color;
                background-color: $main-color;
            }
        }
    }
}

.user-social-box {
    .card-header {
        .add-btn {
            display: inline-block;
            border: none;
            color: #385898;
            background-color: transparent;
            text-decoration: none;
            padding: 0;
            transition: $transition;

            font: {
                size: 14px;
                weight: 600;
            }

            ;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .card-body {
        margin-bottom: -4px;

        a {
            display: inline-block;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            line-height: 40px;
            background-color: $main-color;
            color: $white-color;
            font-size: 20px;

            margin: {
                right: 8px;
                bottom: 4px;
            }

            ;

            text: {
                align: center;
                decoration: none;
            }

            ;

            &.facebook {
                background-color: #3b5998;
                color: $white-color;
            }

            &.twitter {
                background-color: #1da1f2;
                color: $white-color;
            }

            &.instagram {
                background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
                color: $white-color;
            }

            &.linkedin {
                background-color: #0077b5;
                color: $white-color;
            }

            &.pinterest {
                background-color: #bd081c;
                color: $white-color;
            }

            &.stack-overflow {
                background-color: #f48024;
                color: $white-color;
            }
        }
    }
}

.timeline-story-content {
    .timeline-story-header {
        .info {
            h6 {
                color: #616770;
                margin-bottom: 3px;

                a {
                    display: inline-block;
                    color: $black-color;

                    font: {
                        weight: 600;
                    }

                    ;
                }
            }

            span {
                font-size: 14px;
                color: #616770;
            }
        }
    }

    .card-body {
        p {
            color: #727E8C;
        }

        iframe {
            width: 100%;
            height: 400px;
            border: none;
        }
    }

    .feedback-summary {
        a {
            text-decoration: none;
            color: #686c71;
            margin-right: 15px;
            padding-right: 10px;
            border-right: 1px solid #eeeeee;

            i {
                font-size: 20px;
                position: relative;
                top: 3px;
                margin-right: 3px;
            }

            &:hover {
                color: $main-color;
            }

            &:last-child {
                margin-right: 0;
                padding-right: 0;
                border-right: none;
            }
        }
    }
}

.user-events-box {
    .card-body {
        ul {
            margin: {
                left: -5px;
                right: -5px;
                top: -10px;
            }

            ;

            li {
                flex: 0 0 50%;
                max-width: 50%;

                padding: {
                    left: 5px;
                    right: 5px;
                    top: 10px;
                }

                ;

                a {
                    color: #616770;
                    display: block;
                    font-size: 14px;
                    border-radius: 5px;
                    border: 1px solid #eeeeee;
                    padding: 25px 10px;

                    text: {
                        align: center;
                        decoration: none;
                    }

                    i {
                        margin-bottom: 10px;
                        background: #3578e5;
                        display: inline-block;
                        color: $white-color;
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        line-height: 35px;
                        font-size: 20px;
                    }

                    span {
                        display: block;
                        color: $black-color;
                        margin-bottom: 2px;

                        font: {
                            size: $font-size;
                            weight: 600;
                        }

                        ;
                    }

                    &:hover {
                        border-color: $main-color;
                    }
                }
            }
        }
    }
}

.user-friend-request-box {
    .card-body {
        ul {
            li {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }

                h6 {
                    margin-bottom: 2px;

                    font: {
                        size: $font-size;
                        weight: 600;
                    }

                    ;

                    a {
                        color: $black-color;
                    }
                }

                span {
                    color: #616770;
                    font-size: 14px;

                    a {
                        color: #616770;
                    }
                }

                .btn-box {
                    margin-top: 5px;

                    a {
                        text-decoration: none;
                        border: 1px solid #eeeeee;
                        color: #616770;
                        font-size: 18px;
                        display: inline-block;
                        border-radius: 50%;
                        width: 28px;
                        height: 28px;
                        line-height: 30px;
                        text-align: center;

                        &:hover {
                            color: $white-color;
                            border-color: $main-color;
                            background-color: $main-color;
                        }
                    }
                }
            }
        }
    }
}

.user-trends-box {
    .card-body {
        ul {
            li {
                a {
                    text-decoration: none;
                    color: #616770;
                    font-size: 14px;
                    display: block;
                    border-bottom: 1px solid #eeeeee;

                    padding: {
                        top: 10px;
                        bottom: 10px;
                    }

                    ;

                    span {
                        display: block;
                        color: $black-color;

                        font: {
                            size: 16px;
                            weight: 600;
                        }

                        ;

                        margin: {
                            top: 2px;
                            bottom: 2px;
                        }

                        ;
                    }
                }

                &:first-child {
                    a {
                        border-top: 1px solid #eeeeee;
                    }
                }
            }
        }
    }
}

.post-share-content {
    background-color: $white-color;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    border-radius: 5px;
    padding: 25px;

    .post-share-field {
        position: relative;

        label {
            display: block;
            margin-bottom: 0;
            position: absolute;
            left: 0;
            top: 0;

            img {
                width: 60px;
                height: 60px;
                border-radius: 50%;
            }
        }

        .form-control {
            border: none;
            border-radius: 0;
            padding: 20px 0 0 75px;
        }
    }

    .post-share-footer {
        border-top: 1px solid #eeeeee;

        .post-share-info {
            a {
                font-size: 25px;
                color: #828D99;
                margin-right: 10px;
                line-height: 1;
                text-decoration: none;

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    color: $main-color;
                }
            }
        }

        .post-btn {
            a {
                color: $black-color;
                text-decoration: none;
                border-radius: 5px;
                padding: 11px 35px 9px;
                background-color: #E2ECFF;
                text-transform: uppercase;

                font: {
                    size: $font-size;
                    weight: 600;
                }

                ;

                &:hover {
                    color: $white-color;
                    background-color: $main-color;
                }
            }
        }
    }
}

.user-about-me-box {
    p {
        color: #727E8C;
        margin-bottom: 10px;
    }
}

.user-biography-box {
    p {
        color: #727E8C;
        margin-bottom: 10px;
    }
}

.user-friends-box {
    padding-bottom: 10px;

    .single-friends-box {
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 15px;
        margin-bottom: 15px;
        width: 100%;

        img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
        }

        .info {
            h5 {
                margin-bottom: 2px;

                font: {
                    size: $font-size;
                    weight: 600;
                }

                ;

                a {
                    color: $black-color;
                }
            }

            span {
                color: #616770;
                font-size: 14px;

                a {
                    color: #616770;
                }
            }
        }
    }
}

.user-settings-box {
    h3 {
        text-transform: uppercase;
        margin-bottom: 25px;

        font: {
            size: $font-size;
            weight: 600;
        }

        ;

        i {
            width: 35px;
            height: 35px;
            line-height: 36px;
            border-radius: 50%;
            text-align: center;
            background-color: #E2ECFF;
            font-size: 20px;
            margin-right: 5px;
            transition: $transition;
            position: relative;
            top: 2px;
        }

        &:hover {
            i {
                color: $white-color;
                background-color: $main-color;
            }
        }

        &:not(:first-child) {
            margin-top: 10px;
        }
    }

    form {
        .form-group {
            margin-bottom: 20px;

            label {
                color: #6c757d;
                margin-bottom: 8px;
                display: block;
                font-size: 14px;
            }

            .form-control {
                padding-left: 15px;
                background-color: $white-color;
                border: 1px solid #ced4da;
                border-radius: 5px;
                color: $black-color;
                font-size: 14px;
                height: 44px;
                transition: $transition;

                &::placeholder {
                    color: #6c757d;
                }

                &:focus {
                    border-color: #b1bbc4;
                }
            }

            textarea.form-control {
                height: auto;
                padding-top: 15px;
            }
        }

        .btn-box {
            margin-top: 10px;

            .submit-btn {
                border-radius: 5px;
                background-color: $main-color;
                color: $white-color;
                text-decoration: none;
                padding: 10px 30px;
                border: none;
                transition: $transition;

                font: {
                    size: $font-size;
                    weight: 600;
                }

                ;

                i {
                    font-size: 20px;
                    line-height: 1;
                    margin-right: 2px;
                    position: relative;
                    top: 3px;
                }

                &:hover {
                    opacity: .80;
                }
            }
        }
    }
}

/*================================================
Gallery CSS
=================================================*/
.single-gallery-image {
    img {
        cursor: -webkit-zoom-in;
        cursor: zoom-in;
    }
}

/*================================================
Login CSS
=================================================*/
.login-area {
    height: 100vh;
    position: relative;
    z-index: 1;
    background: #f6f7fb;

    &.bg-image {
        background: {
            image: url(../../assets/img/login-bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }

        ;
    }

    .d-table {
        width: 100%;
        height: 100%;

        &-cell {
            vertical-align: middle;
        }
    }

    .login-form {
        max-width: 480px;
        background: $white-color;
        border-radius: 5px;
        overflow: hidden;
        text-align: center;
        padding: 50px;
        box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);

        margin: {
            left: auto;
            right: auto;
        }

        ;

        h2 {
            font: {
                size: 30px;
                weight: 600;
            }

            ;

            margin: {
                top: 30px;
                bottom: 0;
            }

            ;
        }

        form {
            margin-top: 25px;

            .form-group {
                margin-bottom: 30px;
                width: 100%;
                position: relative;

                .label-title {
                    margin-bottom: 0;
                    position: absolute;
                    display: block;
                    left: 0;
                    top: 0;
                    pointer-events: none;
                    width: 100%;
                    height: 100%;
                    color: $black-color;

                    i {
                        position: absolute;
                        left: 0;
                        transition: $transition;
                        top: 9px;

                        font: {
                            size: 22px;
                        }

                        ;
                    }

                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 0;
                        height: 2px;
                        transition: $transition;
                        background: $main-color;
                    }
                }

                .form-control {
                    border-radius: 0;
                    border: none;
                    border-bottom: 2px solid #adadad;
                    padding: 0 0 0 32px;
                    color: $black-color;
                    height: 45px;
                    transition: $transition;

                    font: {
                        family: $font-family;
                        size: 17px;
                        weight: 500;
                    }

                    ;

                    &::placeholder {
                        color: #A1A1A1;
                        transition: $transition;
                    }

                    &:focus {
                        padding-left: 5px;

                        &::placeholder {
                            color: transparent;
                        }
                    }

                    &:focus+.label-title::before {
                        width: 100%;
                    }

                    &:focus+.label-title {
                        color: #A1A1A1;

                        i {
                            top: -22px;
                        }
                    }
                }

                .remember-forgot {
                    text-align: left;
                    position: relative;
                    overflow: hidden;

                    .forgot-password {
                        float: right;
                        color: #0ca7ec;
                        text-decoration: none !important;
                    }
                }

                .checkbox-box {
                    margin-bottom: 0;
                    cursor: pointer;
                    user-select: none;
                    padding-left: 25px;
                    color: #6c757d;

                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;

                        &:checked~.checkmark {
                            background-color: #2196F3;
                        }

                        &:checked~.checkmark:after {
                            display: block;
                        }
                    }

                    .checkmark {
                        position: absolute;
                        top: 1px;
                        left: 0;
                        height: 18px;
                        width: 18px;
                        overflow: hidden;
                        background-color: #eeeeee;
                        transition: $transition;

                        &:after {
                            content: "\e9f0";
                            position: absolute;
                            display: none;
                            left: 0;
                            top: -4.5px;
                            color: $white-color;

                            font: {
                                family: 'boxicons' !important;
                                weight: normal;
                                style: normal;
                                size: 17px;
                                variant: normal;
                            }

                            ;
                        }
                    }

                    a {
                        text-decoration: none;
                        color: $black-color;
                    }
                }
            }

            .login-btn {
                padding: 12px 35px 11px;
                border-radius: 30px;
                border: none;
                text-transform: uppercase;
                transition: $transition;
                background-color: $main-color;
                color: $white-color;

                font: {
                    size: $font-size;
                    weight: 600;
                }

                ;

                &:hover,
                &:focus {
                    background-color: $black-color;
                    color: $white-color;
                }
            }

            p {
                margin-top: 20px;
                color: #6c757d;

                a {
                    text-decoration: none !important;
                    color: #0ca7ec;
                }
            }
        }

        .form-group {
            text-align: left;
        }
    }
}

/*================================================
Register CSS
=================================================*/
.register-area {
    height: 100vh;
    position: relative;
    z-index: 1;
    background: #f6f7fb;

    &.bg-image {
        background: {
            image: url(../../assets/img/register-bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }

        ;
    }

    .d-table {
        width: 100%;
        height: 100%;

        &-cell {
            vertical-align: middle;
        }
    }

    .register-form {
        max-width: 480px;
        background: $white-color;
        border-radius: 5px;
        overflow: hidden;
        text-align: center;
        padding: 50px;
        box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);

        margin: {
            left: auto;
            right: auto;
        }

        ;

        h2 {
            font: {
                size: 30px;
                weight: 600;
            }

            ;

            margin: {
                top: 30px;
                bottom: 0;
            }

            ;
        }

        form {
            margin-top: 25px;

            .form-group {
                margin-bottom: 30px;
                width: 100%;
                position: relative;

                .label-title {
                    margin-bottom: 0;
                    position: absolute;
                    display: block;
                    left: 0;
                    top: 0;
                    pointer-events: none;
                    width: 100%;
                    height: 100%;
                    color: $black-color;

                    i {
                        position: absolute;
                        left: 0;
                        transition: $transition;
                        top: 9px;

                        font: {
                            size: 22px;
                        }

                        ;
                    }

                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 0;
                        height: 2px;
                        transition: $transition;
                        background: $main-color;
                    }
                }

                .form-control {
                    border-radius: 0;
                    border: none;
                    border-bottom: 2px solid #adadad;
                    padding: 0 0 0 32px;
                    color: $black-color;
                    height: 45px;
                    transition: $transition;

                    font: {
                        family: $font-family;
                        size: 17px;
                        weight: 500;
                    }

                    ;

                    &::placeholder {
                        color: #A1A1A1;
                        transition: $transition;
                    }

                    &:focus {
                        padding-left: 5px;

                        &::placeholder {
                            color: transparent;
                        }
                    }

                    &:focus+.label-title::before {
                        width: 100%;
                    }

                    &:focus+.label-title {
                        color: #A1A1A1;

                        i {
                            top: -22px;
                        }
                    }
                }

                .terms-conditions {
                    text-align: left;
                    position: relative;
                    overflow: hidden;
                }

                .checkbox-box {
                    margin-bottom: 0;
                    cursor: pointer;
                    user-select: none;
                    padding-left: 25px;
                    color: #6c757d;

                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;

                        &:checked~.checkmark {
                            background-color: #2196F3;
                        }

                        &:checked~.checkmark:after {
                            display: block;
                        }
                    }

                    .checkmark {
                        position: absolute;
                        top: 1px;
                        left: 0;
                        height: 18px;
                        width: 18px;
                        overflow: hidden;
                        background-color: #eeeeee;
                        transition: $transition;

                        &:after {
                            content: "\e9f0";
                            position: absolute;
                            display: none;
                            left: 0;
                            top: -4.5px;
                            color: $white-color;

                            font: {
                                family: 'boxicons' !important;
                                weight: normal;
                                style: normal;
                                size: 17px;
                                variant: normal;
                            }

                            ;
                        }
                    }

                    a {
                        text-decoration: none;
                        color: $black-color;
                    }
                }
            }

            .register-btn {
                padding: 12px 35px 11px;
                border-radius: 30px;
                border: none;
                text-transform: uppercase;
                transition: $transition;
                background-color: $main-color;
                color: $white-color;

                font: {
                    size: $font-size;
                    weight: 600;
                }

                ;

                &:hover,
                &:focus {
                    background-color: $black-color;
                    color: $white-color;
                }
            }

            p {
                margin-top: 20px;
                color: #6c757d;

                a {
                    text-decoration: none !important;
                    color: #0ca7ec;
                }
            }
        }

        .form-group {
            text-align: left;
        }
    }
}

/*================================================
Reset Password CSS
=================================================*/
.reset-password-area {
    height: 100vh;
    position: relative;
    z-index: 1;
    background: #f6f7fb;

    &.bg-image {
        background: {
            image: url(../../assets/img/reset-password-bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }

        ;
    }

    .d-table {
        width: 100%;
        height: 100%;

        &-cell {
            vertical-align: middle;
        }
    }

    .reset-password-content {
        max-width: 900px;
        background: $white-color;
        border-radius: 5px;
        overflow: hidden;
        padding: 50px 60px 50px 30px;
        box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);

        margin: {
            left: auto;
            right: auto;
        }

        ;

        .image {
            text-align: center;
        }

        .reset-password-form {
            padding-left: 30px;

            h2 {
                font: {
                    size: 30px;
                    weight: 600;
                }

                ;

                margin: {
                    top: 30px;
                    bottom: 0;
                }

                ;
            }

            form {
                margin-top: 25px;

                .form-group {
                    margin-bottom: 30px;
                    width: 100%;
                    position: relative;

                    .label-title {
                        margin-bottom: 0;
                        position: absolute;
                        display: block;
                        left: 0;
                        top: 0;
                        pointer-events: none;
                        width: 100%;
                        height: 100%;
                        color: $black-color;

                        i {
                            position: absolute;
                            left: 0;
                            transition: $transition;
                            top: 9px;

                            font: {
                                size: 22px;
                            }

                            ;
                        }

                        &::before {
                            content: "";
                            display: block;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 0;
                            height: 2px;
                            transition: $transition;
                            background: $main-color;
                        }
                    }

                    .form-control {
                        border-radius: 0;
                        border: none;
                        border-bottom: 2px solid #adadad;
                        padding: 0 0 0 32px;
                        color: $black-color;
                        height: 45px;
                        transition: $transition;

                        font: {
                            family: $font-family;
                            size: 17px;
                            weight: 500;
                        }

                        ;

                        &::placeholder {
                            color: #A1A1A1;
                            transition: $transition;
                        }

                        &:focus {
                            padding-left: 5px;

                            &::placeholder {
                                color: transparent;
                            }
                        }

                        &:focus+.label-title::before {
                            width: 100%;
                        }

                        &:focus+.label-title {
                            color: #A1A1A1;

                            i {
                                top: -22px;
                            }
                        }
                    }
                }

                .reset-password-btn {
                    padding: 12px 35px 11px;
                    border-radius: 30px;
                    border: none;
                    text-transform: uppercase;
                    transition: $transition;
                    background-color: $main-color;
                    color: $white-color;

                    font: {
                        size: $font-size;
                        weight: 600;
                    }

                    ;

                    &:hover,
                    &:focus {
                        background-color: $black-color;
                        color: $white-color;
                    }
                }
            }
        }
    }
}

/*================================================
Forgot Password CSS
=================================================*/
.forgot-password-area {
    height: 100vh;
    position: relative;
    z-index: 1;
    background: #f6f7fb;

    &.bg-image {
        background: {
            image: url(../../assets/img/forgot-password-bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }

        ;
    }

    .d-table {
        width: 100%;
        height: 100%;

        &-cell {
            vertical-align: middle;
        }
    }

    .forgot-password-content {
        max-width: 900px;
        background: $white-color;
        border-radius: 5px;
        overflow: hidden;
        padding: 50px 60px 50px 30px;
        box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);

        margin: {
            left: auto;
            right: auto;
        }

        ;

        .image {
            text-align: center;
        }

        .forgot-password-form {
            padding-left: 30px;

            h2 {
                font: {
                    size: 30px;
                    weight: 600;
                }

                ;

                margin: {
                    top: 30px;
                    bottom: 0;
                }

                ;
            }

            p {
                color: #6c757d;
                margin-top: 7px;
            }

            form {
                margin-top: 25px;

                .form-group {
                    margin-bottom: 30px;
                    width: 100%;
                    position: relative;

                    .label-title {
                        margin-bottom: 0;
                        position: absolute;
                        display: block;
                        left: 0;
                        top: 0;
                        pointer-events: none;
                        width: 100%;
                        height: 100%;
                        color: $black-color;

                        i {
                            position: absolute;
                            left: 0;
                            transition: $transition;
                            top: 9px;

                            font: {
                                size: 22px;
                            }

                            ;
                        }

                        &::before {
                            content: "";
                            display: block;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 0;
                            height: 2px;
                            transition: $transition;
                            background: $main-color;
                        }
                    }

                    .form-control {
                        border-radius: 0;
                        border: none;
                        border-bottom: 2px solid #adadad;
                        padding: 0 0 0 32px;
                        color: $black-color;
                        height: 45px;
                        transition: $transition;

                        font: {
                            family: $font-family;
                            size: 17px;
                            weight: 500;
                        }

                        ;

                        &::placeholder {
                            color: #A1A1A1;
                            transition: $transition;
                        }

                        &:focus {
                            padding-left: 5px;

                            &::placeholder {
                                color: transparent;
                            }
                        }

                        &:focus+.label-title::before {
                            width: 100%;
                        }

                        &:focus+.label-title {
                            color: #A1A1A1;

                            i {
                                top: -22px;
                            }
                        }
                    }
                }

                .forgot-password-btn {
                    padding: 12px 35px 11px;
                    border-radius: 30px;
                    border: none;
                    text-transform: uppercase;
                    transition: $transition;
                    background-color: $main-color;
                    color: $white-color;

                    font: {
                        size: $font-size;
                        weight: 600;
                    }

                    ;

                    &:hover,
                    &:focus {
                        background-color: $black-color;
                        color: $white-color;
                    }
                }
            }
        }
    }
}

/*================================================
Lock Screen CSS
=================================================*/
.lock-screen-area {
    height: 100vh;
    position: relative;
    z-index: 1;
    background: #f6f7fb;

    &.bg-image {
        background: {
            image: url(../../assets/img/lock-screen-bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }

        ;
    }

    .d-table {
        width: 100%;
        height: 100%;

        &-cell {
            vertical-align: middle;
        }
    }

    .lock-screen-content {
        max-width: 900px;
        background: $white-color;
        border-radius: 5px;
        overflow: hidden;
        padding: 50px 60px 50px 30px;
        box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);

        margin: {
            left: auto;
            right: auto;
        }

        ;

        .image {
            text-align: center;
        }

        .lock-screen-form {
            padding-left: 30px;

            h2 {
                font: {
                    size: 30px;
                    weight: 600;
                }

                ;

                margin: {
                    top: 30px;
                    bottom: 0;
                }

                ;
            }

            form {
                margin-top: 25px;

                .form-group {
                    margin-bottom: 30px;
                    width: 100%;
                    position: relative;

                    .label-title {
                        margin-bottom: 0;
                        position: absolute;
                        display: block;
                        left: 0;
                        top: 0;
                        pointer-events: none;
                        width: 100%;
                        height: 100%;
                        color: $black-color;

                        i {
                            position: absolute;
                            left: 0;
                            transition: $transition;
                            top: 9px;

                            font: {
                                size: 22px;
                            }

                            ;
                        }

                        &::before {
                            content: "";
                            display: block;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 0;
                            height: 2px;
                            transition: $transition;
                            background: $main-color;
                        }
                    }

                    .form-control {
                        border-radius: 0;
                        border: none;
                        border-bottom: 2px solid #adadad;
                        padding: 0 0 0 32px;
                        color: $black-color;
                        height: 45px;
                        transition: $transition;

                        font: {
                            family: $font-family;
                            size: 17px;
                            weight: 500;
                        }

                        ;

                        &::placeholder {
                            color: #A1A1A1;
                            transition: $transition;
                        }

                        &:focus {
                            padding-left: 5px;

                            &::placeholder {
                                color: transparent;
                            }
                        }

                        &:focus+.label-title::before {
                            width: 100%;
                        }

                        &:focus+.label-title {
                            color: #A1A1A1;

                            i {
                                top: -22px;
                            }
                        }
                    }
                }

                .lock-screen-btn {
                    padding: 12px 35px 11px;
                    border-radius: 30px;
                    border: none;
                    text-transform: uppercase;
                    transition: $transition;
                    background-color: $main-color;
                    color: $white-color;

                    font: {
                        size: $font-size;
                        weight: 600;
                    }

                    ;

                    &:hover,
                    &:focus {
                        background-color: $black-color;
                        color: $white-color;
                    }
                }
            }
        }
    }
}

/*================================================
Error 404 CSS
=================================================*/
.error-404-area {
    height: 100vh;
    position: relative;
    z-index: 1;
    background: #f6f7fb;

    &.bg-image {
        background: {
            image: url(../../assets/img/error-404-bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }

        ;
    }

    .d-table {
        width: 100%;
        height: 100%;

        &-cell {
            vertical-align: middle;
        }
    }

    .notfound {
        position: relative;
        max-width: 520px;
        z-index: 1;
        text-align: center;

        margin: {
            left: auto;
            right: auto;
        }

        ;

        .notfound-bg {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;

            div {
                width: 100%;
                background: $white-color;
                border-radius: 90px;
                height: 125px;

                &:nth-child(1) {
                    box-shadow: 5px 5px 0 0 #f3f3f3;
                }

                &:nth-child(2) {
                    transform: scale(1.3);
                    box-shadow: 5px 5px 0 0 #f3f3f3;
                    position: relative;
                    z-index: 10;
                }

                &:nth-child(3) {
                    box-shadow: 5px 5px 0 0 #f3f3f3;
                    position: relative;
                    z-index: 90;
                }
            }
        }

        h1 {
            text-transform: uppercase;

            font: {
                size: 100px;
                weight: 900;
            }

            ;

            margin: {
                top: 0;
                bottom: 5px;
            }

            ;
            background: url(../../assets/img/oops-bg.jpg) no-repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-size: cover;
            background-position: center;
        }

        h3 {
            margin-bottom: 12px;

            font: {
                weight: 600;
                size: 25px;
            }

            ;
        }

        p {
            color: #6084a4;
            margin-bottom: 20px;
            line-height: 1.7;
        }

        .default-btn {
            transition: $transition;
            text-decoration: none;
            padding: 13px 35px 12px;
            border-radius: 30px;
            text-transform: uppercase;
            display: inline-block;
            transition: $transition;
            background-color: $main-color;
            color: $white-color;

            font: {
                size: $font-size;
                weight: 600;
            }

            ;

            &:hover,
            &:focus {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }
}

/*================================================
Error 500 CSS
=================================================*/
.error-500-area {
    height: 100vh;
    position: relative;
    z-index: 1;
    background: #f6f7fb;

    &.bg-image {
        background: {
            image: url(../../assets/img/error-500-bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }

        ;
    }

    .d-table {
        width: 100%;
        height: 100%;

        &-cell {
            vertical-align: middle;
        }
    }

    .notfound {
        position: relative;
        max-width: 520px;
        z-index: 1;
        text-align: center;

        margin: {
            left: auto;
            right: auto;
        }

        ;

        .notfound-bg {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;

            div {
                width: 100%;
                background: $white-color;
                border-radius: 90px;
                height: 125px;

                &:nth-child(1) {
                    box-shadow: 5px 5px 0 0 #f3f3f3;
                }

                &:nth-child(2) {
                    transform: scale(1.3);
                    box-shadow: 5px 5px 0 0 #f3f3f3;
                    position: relative;
                    z-index: 10;
                }

                &:nth-child(3) {
                    box-shadow: 5px 5px 0 0 #f3f3f3;
                    position: relative;
                    z-index: 90;
                }
            }
        }

        h1 {
            text-transform: uppercase;

            font: {
                size: 100px;
                weight: 900;
            }

            ;

            margin: {
                top: 0;
                bottom: 5px;
            }

            ;
            background: url(../../assets/img/oops-bg.jpg) no-repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-size: cover;
            background-position: center;
        }

        h3 {
            margin-bottom: 12px;

            font: {
                weight: 600;
                size: 25px;
            }

            ;
        }

        p {
            color: #6084a4;
            margin-bottom: 20px;
            line-height: 1.7;
        }

        .default-btn {
            transition: $transition;
            text-decoration: none;
            padding: 13px 35px 12px;
            border-radius: 30px;
            text-transform: uppercase;
            display: inline-block;
            transition: $transition;
            background-color: $main-color;
            color: $white-color;

            font: {
                size: $font-size;
                weight: 600;
            }

            ;

            &:hover,
            &:focus {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }
}

/*================================================
Coming Soon CSS
=================================================*/
.coming-soon-area {
    height: 100vh;
    position: relative;
    z-index: 1;
    background: #f6f7fb;

    &.bg-image {
        background: {
            image: url(../../assets/img/coming-soon-bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }

        ;
    }

    .d-table {
        width: 100%;
        height: 100%;

        &-cell {
            vertical-align: middle;
        }
    }

    .coming-soon-content {
        max-width: 850px;
        background: $white-color;
        border-radius: 5px;
        overflow: hidden;
        text-align: center;
        padding: 50px 60px 50px 30px;
        box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);

        margin: {
            left: auto;
            right: auto;
        }

        ;

        .logo {
            display: inline-block;
        }

        h2 {
            font: {
                size: 35px;
                weight: 600;
            }

            ;

            margin: {
                top: 30px;
                bottom: 0;
            }

            ;
        }

        #timer {
            margin-top: 50px;

            div {
                background-color: #333333;
                color: $white-color;
                width: 100px;
                height: 110px;
                border-radius: 5px;

                font: {
                    size: 35px;
                    weight: 600;
                }

                ;

                margin: {
                    left: 10px;
                    right: 10px;
                }

                ;

                span {
                    display: block;
                    margin-top: -4px;

                    font: {
                        size: 15px;
                        weight: 500;
                    }

                    ;
                }
            }
        }

        form {
            max-width: 500px;

            margin: {
                left: auto;
                right: auto;
                top: 50px;
            }

            ;

            .form-group {
                margin-bottom: 25px;
                width: 100%;
                position: relative;

                .label-title {
                    margin-bottom: 0;
                    position: absolute;
                    display: block;
                    left: 0;
                    top: 0;
                    pointer-events: none;
                    width: 100%;
                    height: 100%;
                    color: $black-color;

                    i {
                        position: absolute;
                        left: 0;
                        transition: $transition;
                        top: 9px;

                        font: {
                            size: 22px;
                        }

                        ;
                    }

                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 0;
                        height: 2px;
                        transition: $transition;
                        background: $main-color;
                    }
                }

                .form-control {
                    border-radius: 0;
                    border: none;
                    border-bottom: 2px solid #adadad;
                    padding: 0 0 0 32px;
                    color: $black-color;
                    height: 45px;
                    transition: $transition;

                    font: {
                        family: $font-family;
                        size: 17px;
                        weight: 500;
                    }

                    ;

                    &::placeholder {
                        color: #A1A1A1;
                        transition: $transition;
                    }

                    &:focus {
                        padding-left: 5px;

                        &::placeholder {
                            color: transparent;
                        }
                    }

                    &:focus+.label-title::before {
                        width: 100%;
                    }

                    &:focus+.label-title {
                        color: #A1A1A1;

                        i {
                            top: -22px;
                        }
                    }
                }
            }

            .default-btn {
                padding: 12px 35px 11px;
                border-radius: 30px;
                border: none;
                text-transform: uppercase;
                display: inline-block;
                transition: $transition;
                background-color: $main-color;
                color: $white-color;

                font: {
                    size: $font-size;
                    weight: 600;
                }

                ;

                &:hover,
                &:focus {
                    background-color: $black-color;
                    color: $white-color;
                }
            }

            p {
                color: #6084a4;
                line-height: 1.7;

                margin: {
                    bottom: 0;
                    top: 20px;
                }

                ;
            }
        }
    }
}

/*================================================
Maintenance CSS
=================================================*/
.maintenance-area {
    height: 100vh;
    position: relative;
    z-index: 1;
    background: #f6f7fb;

    &.bg-image {
        background: {
            image: url(../../assets/img/maintenance-bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }

        ;
    }

    .d-table {
        width: 100%;
        height: 100%;

        &-cell {
            vertical-align: middle;
        }
    }

    .maintenance-content {
        max-width: 850px;
        background: $white-color;
        border-radius: 5px;
        overflow: hidden;
        text-align: center;
        padding: 50px 60px 50px 30px;
        box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);

        margin: {
            left: auto;
            right: auto;
        }

        ;

        .logo {
            display: inline-block;
        }

        h2 {
            background: url(../../assets/img/oops-bg.jpg) no-repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-size: cover;
            background-position: center;
            text-transform: uppercase;

            font: {
                size: 80px;
                weight: 900;
            }

            ;

            margin: {
                top: 30px;
                bottom: 5px;
            }

            ;
        }

        p {
            color: #6084a4;
            line-height: 1.7;
            font-size: 17px;
            max-width: 500px;

            margin: {
                left: auto;
                right: auto;
                bottom: 20px;
                top: 0;
            }

            ;
        }

        .default-btn {
            transition: $transition;
            text-decoration: none;
            padding: 13px 35px 12px;
            border-radius: 30px;
            text-transform: uppercase;
            display: inline-block;
            transition: $transition;
            background-color: $main-color;
            color: $white-color;

            font: {
                size: $font-size;
                weight: 600;
            }

            ;

            &:hover,
            &:focus {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }
}

/*================================================
Not Authorized CSS
=================================================*/
.not-authorized-area {
    height: 100vh;
    position: relative;
    z-index: 1;
    background: #f6f7fb;

    &.bg-image {
        background: {
            image: url(../../assets/img/not-authorized-bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }

        ;
    }

    .d-table {
        width: 100%;
        height: 100%;

        &-cell {
            vertical-align: middle;
        }
    }

    .not-authorized-content {
        max-width: 850px;
        background: $white-color;
        border-radius: 5px;
        overflow: hidden;
        text-align: center;
        padding: 50px 60px 50px 30px;
        box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);

        margin: {
            left: auto;
            right: auto;
        }

        ;

        .logo {
            display: inline-block;
        }

        h2 {
            background: url(../../assets/img/oops-bg.jpg) no-repeat;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-size: cover;
            background-position: center;
            text-transform: uppercase;

            font: {
                size: 45px;
                weight: 900;
            }

            ;

            margin: {
                top: 30px;
                bottom: 5px;
            }

            ;
        }

        p {
            color: #6084a4;
            line-height: 1.7;
            max-width: 500px;

            margin: {
                left: auto;
                right: auto;
                bottom: 20px;
                top: 0;
            }

            ;
        }

        .default-btn {
            transition: $transition;
            text-decoration: none;
            padding: 13px 35px 12px;
            border-radius: 30px;
            text-transform: uppercase;
            display: inline-block;
            transition: $transition;
            background-color: $main-color;
            color: $white-color;

            font: {
                size: $font-size;
                weight: 600;
            }

            ;

            &:hover,
            &:focus {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }
}

/*================================================
FAQ CSS
=================================================*/
.faq-search {
    text-align: center;
    background-color: $white-color;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    border-radius: 5px;
    padding: 35px 25px;

    h2 {
        margin-bottom: 0;

        font: {
            size: 30px;
            weight: 600;
        }

        ;
    }

    form {
        max-width: 600px;
        position: relative;

        margin: {
            left: auto;
            right: auto;
            top: 30px;
            bottom: 20px;
        }

        ;

        .form-control {
            background-color: $white-color;
            color: $black-color;
            border: none;
            transition: $transition;
            border-radius: 30px;
            box-shadow: 0 5px 28.5px 1.5px rgba(149, 152, 200, .2) !important;
            height: 55px;
            font-size: 16px;
            padding-left: 20px;

            &::placeholder {
                transition: $transition;
            }

            &:focus {
                &::placeholder {
                    color: transparent;
                }
            }
        }

        button {
            position: absolute;
            right: 5px;
            top: 5px;
            border: none;
            text-transform: uppercase;
            border-radius: 30px;
            transition: $transition;
            padding: 12px 30px 11px;
            background-color: $main-color;
            color: $white-color;

            font: {
                size: $font-size;
                weight: 600;
            }

            ;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    p {
        margin-bottom: 0;
        color: #6084a4;
    }
}

.faq-area {
    background-color: $white-color;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    border-radius: 5px;
    padding: 35px 25px;

    .title {
        margin-bottom: 50px;
        text-align: center;

        h2 {
            margin-bottom: 10px;

            font: {
                size: 30px;
                weight: 600;
            }

            ;
        }

        p {
            color: #6084a4;
            max-width: 600px;

            margin: {
                left: auto;
                right: auto;
                bottom: 0;
            }

            ;
        }
    }
}

.faq-accordion-tab {
    .tabs {
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 50px;
        text-align: center;

        li {
            margin: {
                left: 10px;
                right: 10px;
            }

            ;

            a {
                color: $black-color;
                text-decoration: none;
                border-radius: 5px;
                padding: 20px;
                display: block;
                box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, .13) !important;

                font: {
                    size: $font-size;
                    weight: 600;
                }

                ;

                i {
                    font-size: 30px;
                }

                span {
                    display: block;
                    margin-top: 5px;
                }

                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }

            &.current {
                a {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}

.faq-accordion {
    padding: {
        left: 20px;
        right: 20px;
    }

    ;

    .accordion {
        list-style-type: none;
        padding-left: 0;

        margin: {
            bottom: 0;
        }

        .accordion-item {
            border: 0;
            display: block;
            box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
            background: $white-color;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .accordion-title {
            padding: 15px 20px 10px 51px;
            color: $black-color;
            text-decoration: none;
            position: relative;
            display: block;

            font: {
                size: $font-size;
                weight: 600;
            }

            i {
                position: absolute;
                left: 0;
                top: 0;
                width: 40px;
                text-align: center;
                height: 100%;
                background: $main-color;
                color: $white-color;
                font-size: 22px;

                &::before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                }
            }

            &.active {
                i {
                    &::before {
                        content: "\eaf8";
                    }
                }
            }
        }

        .accordion-content {
            display: none;
            position: relative;
            color: #727E8C;
            padding: 15px 15px;
            border-top: 1px solid #eeeeee;

            &.fast {
                display: block;
            }
        }
    }
}

.tab_content {
    .tabs_item {
        display: none;

        &:first-child {
            display: block;
        }
    }
}

/*================================================
Pricing CSS
=================================================*/
.single-pricing-box {
    margin-bottom: 30px;
    text-align: center;
    background-color: $white-color;
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.08);
    padding: 40px 30px 28px 30px;
    border-radius: 5px;
    transition: $transition;

    .pricing-header {
        position: relative;
        z-index: 1;
        background-color: $main-color;
        margin: -40px -30px 30px -30px;
        border-radius: 5px 5px 0 0;

        padding: {
            left: 30px;
            right: 30px;
            top: 30px;
            bottom: 25px;
        }

        h3 {
            color: $white-color;

            font: {
                size: 24px;
                weight: 600;
            }

            margin: {
                bottom: 0;
            }
        }
    }

    .price {
        color: $black-color;
        line-height: 50px;
        align-items: baseline;
        position: relative;
        z-index: 1;

        font: {
            size: 40px;
            weight: 600;
        }

        sub {
            line-height: 16px;
            color: #6084a4;
            margin: 0 -8px 0 -9px;
            position: relative;
            top: -2px;
            display: inline-block;

            font: {
                weight: 600;
                size: 16px;
            }
        }
    }

    .price-features-list {
        text-align: left;
        padding: 30px 30px;
        margin: 25px -30px 30px -30px;
        list-style-type: none;

        border: {
            bottom: 1px solid #eeeeee;
            top: 1px solid #eeeeee;
        }

        li {
            position: relative;

            padding: {
                top: 8px;
                bottom: 8px;
                right: 25px;
            }

            color: #6084a4;

            font: {
                weight: 500;
                size: 16px;
            }

            i {
                font-size: 20px;
                color: $main-color;
                position: absolute;
                right: 0;
                top: 10px;

                &.bx-check-double {
                    color: #6ac04f;
                }

                &.bx-x {
                    color: #dd5252;
                }
            }
        }
    }

    .get-started-btn {
        display: inline-block;
        color: $main-color;
        position: relative;
        text-decoration: none;

        font: {
            weight: 600;
            size: 18px;
        }

        &::before {
            width: 0;
            height: 1px;
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: $main-color;
            transition: $transition;
        }

        &:hover {
            &::before {
                width: 100%;
            }
        }
    }

    &.red {
        .pricing-header {
            background-color: #fc6765;
        }

        .get-started-btn {
            color: #fc6765;

            &::before {
                background-color: #fc6765;
            }
        }
    }

    &.orange {
        .pricing-header {
            background-color: #ffa143;
        }

        .get-started-btn {
            color: #ffa143;

            &::before {
                background-color: #ffa143;
            }
        }
    }

    &:hover {
        transform: translateY(-10px);
    }
}

/*================================================
Invoice CSS
=================================================*/
.invoice-area {
    background-color: $white-color;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    border-radius: 5px;
    padding: 25px;
}

.invoice-header {
    h3 {
        font: {
            size: 25px;
            weight: 600;
        }

        ;
    }

    p {
        font: {
            weight: 600;
            size: $font-size;
        }

        ;
    }
}

.invoice-middle {
    h4 {
        font: {
            size: 18px;
            weight: 600;
        }

        ;
    }

    h5 {
        margin-bottom: 12px;

        font: {
            size: $font-size;
            weight: 600;
        }

        ;

        sub {
            margin-left: 50px;
            bottom: 0;
            width: 90px;
            display: inline-block;
            color: #6084a4;

            font: {
                weight: 500;
                size: $font-size;
            }

            ;
        }
    }

    span {
        font: {
            weight: 600;
            size: $font-size;
        }

        ;
    }
}

.invoice-table {
    table {
        margin-bottom: 0;

        thead {
            th {
                text-align: center;
                vertical-align: middle;
                border-bottom: 1px solid #dee2e6;

                font: {
                    size: $font-size;
                    weight: 600;
                }

                ;

                padding: {
                    top: 10px;
                    bottom: 10px;
                    left: 15px;
                    right: 15px;
                }

                ;
            }
        }

        tbody {
            td {
                vertical-align: middle;
                color: #6084a4;
                border-width: 1px;

                font: {
                    size: 14px;
                }

                ;

                padding: {
                    top: 10px;
                    bottom: 10px;
                    left: 15px;
                    right: 15px;
                }

                ;

                &.total,
                &.total-price {
                    color: $black-color;
                    font-size: 17px;
                }
            }
        }
    }
}

.invoice-btn-box {
    .default-btn {
        transition: $transition;
        text-decoration: none;
        padding: 13px 35px 12px;
        border-radius: 30px;
        text-transform: uppercase;
        display: inline-block;
        transition: $transition;
        background-color: $main-color;
        color: $white-color;
        margin-right: 10px;

        font: {
            size: 14px;
            weight: 600;
        }

        ;

        &:hover,
        &:focus {
            background-color: $black-color;
            color: $white-color;
        }
    }

    .optional-btn {
        transition: $transition;
        text-decoration: none;
        padding: 13px 35px 12px;
        border-radius: 30px;
        text-transform: uppercase;
        display: inline-block;
        transition: $transition;
        background-color: #3578e5;
        color: $white-color;

        font: {
            size: 14px;
            weight: 600;
        }

        ;

        &:hover,
        &:focus {
            background-color: $black-color;
            color: $white-color;
        }
    }
}

/*================================================
Timeline CSS
=================================================*/
.timeline {
    position: relative;

    &::before {
        position: absolute;
        content: '';
        width: 4px;
        height: 100%;
        background-color: $main-color;
        left: 14px;
        top: 5px;
        border-radius: 4px;
    }

    .timeline-month {
        position: relative;
        padding: 4px 15px 4px 35px;
        background-color: $main-color;
        display: inline-block;
        border-radius: 30px;
        margin-bottom: 30px;
        color: $white-color;
        font-weight: 600;

        &::before {
            position: absolute;
            content: '';
            width: 20px;
            height: 20px;
            background-color: $white-color;
            border-radius: 100%;
            left: 5px;
            top: 5px;
        }
    }

    .timeline-section {
        padding-left: 35px;
        display: block;
        position: relative;
        margin-bottom: 30px;

        .timeline-date {
            margin-bottom: 15px;
            padding: 3px 15px;
            background: #3578e5;
            position: relative;
            display: inline-block;
            border-radius: 20px;
            color: $white-color;
            font-weight: 600;
        }

        &::before {
            content: '';
            position: absolute;
            width: 30px;
            height: 1px;
            background-color: #3578e5;
            top: 12px;
            left: 20px;
        }

        &::after {
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            background: #3578e5;
            top: 7px;
            left: 11px;
            border-radius: 50%;
        }

        .col-lg-4 {
            margin-bottom: 15px;
        }

        .timeline-box {
            position: relative;
            background-color: $white-color;
            transition: all 0.3s ease;
            overflow: hidden;
            box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);

            border: {
                radius: 15px;
                top-left-radius: 0;
                bottom-right-radius: 0;
            }

            ;

            .box-title {
                padding: 10px 15px;
                font-weight: 600;

                i {
                    margin-right: 2px;
                    font-size: 18px;
                    position: relative;
                    top: 2px;
                }
            }

            .box-content {
                padding: 15px;
                background-color: $white-color;
                border-top: 1px solid #eeeeee;

                .box-item {
                    margin-bottom: 10px;
                    color: #606060;
                    font-size: 14px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    strong {
                        color: $black-color;
                        font-style: italic;
                    }
                }
            }

            .box-footer {
                padding: 10px 15px;
                background-color: $black-color;
                text-align: right;
                font-style: italic;
                color: $white-color;
            }
        }
    }
}


/*================================================
Footer CSS
=================================================*/
.footer-area {
    padding-bottom: 25px;
    border-top: 1px solid #eeeeee;
    padding-top: 25px;

    p {
        font-size: $font-size;
        margin-bottom: 0;
        color: #686868;

        i {
            position: relative;
            top: 2px;
        }

        a {
            display: inline-block;
            color: #686868;

            &:hover {
                color: $main-color;
                text-decoration: none;
            }
        }
    }
}

.not-show {
    display: none;
}

.not-display {
    opacity: 0;
}

.shadow-title {
    // min-width: 8rem;
    // background-color: white;
    // display: inline-block;
    // height: 100%;
    // position: absolute;
    // top: 0;
    // padding-top: 5px;
    // margin-left: -1px;
}

.shadow-column {
    min-width: 3rem;
    height: 24px;
    position: absolute;
    right: 0;
    top: 0;
    display: inline-block;
    background: rgba(255, 255, 255, 0.7);
}

.apexcharts-menu-item.exportSVG {
    display: none;
}

.bxs-arrow-to-bottom {
    font-size: 25px;
}

.scrolled {
    top: 0 !important;
}

// .sticky-date{
//     position: fixed;
//     top: 15px;
//     z-index: 999;
//     animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
// }
.sankey-chart text {
    font-size: 17px;
    font-weight: 600;
}

.google-visualization-tooltip text {
    font-weight: normal;
}

.sankey-chart svg {
    overflow: visible !important;
}

#analytics-data tbody td:first-child {
    position: sticky;
    left: 0;
    background-color: #FAFAFA;
}

#analytics-data thead td:first-child {
    position: sticky;
    left: 0;
    background-color: #F4F4F5;
}

#analytics-data thead.sticky.top-0 {
    top: 39px !important;
    z-index: 9;
}

#deep-dive-data {
    overflow: hidden;
    .table-wrapper {
        overflow-x: scroll;
    }    
}

#deep-dive-data td.sticky {
    background-color: white;
    border-left-width: 0;
}

#deep-dive-data tr {
    border-top: 1px solid #bcc3d1;
}

#deep-dive-data tr td:nth-child(2) {
    border-left: 1px solid #bcc3d1;
}

.padding-scroll {
    padding-right: 17px;
}