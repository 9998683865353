#pdf_print_area_deepdive {
    width: 1800px;
    margin-top: 2em;

    // #pdf_print_area_1,
    // #pdf_print_area_2 {
    //     height: 100%;
    //     // border: 1px solid red;
    // }

    .card-body{
        overflow-x: hidden !important;
        overflow-y: hidden !important;
    }

    // .row > * {
    //     padding-right: 0 !important;
    // }
}