ngb-datepicker.dropdown-menu {
    background: #FFFFFF;
    box-shadow: 0px 8px 10px rgba(60, 41, 69, 0.1);
    border-radius: 0px 0px 12px 12px;

    [ngbDatepickerDayView] {
        border-radius: 50%;
        font-size: 14px;
    }

    .ngb-dp-header {
        background: #fff;
    }

    .ngb-dp-weekdays {
        background: #fff;
    }

    .ngb-dp-weekday,
    .ngb-dp-week-number {
        font-style: normal;
        color: inherit;
    }

    ngb-datepicker-navigation-select>.custom-select {
        font-weight: 600;
    }
    .ngb-dp-arrow button{
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(60, 41, 69, 0.07);
        border-radius: 100px;
        width: 24px;
        height: 24px;
        font-size: 12px;
        span {
            border-color: #212529;
        }
    }
    ngb-datepicker-navigation {
        display: flex;
        align-items: baseline;
        margin-bottom: 10px;
    }
    select {
        border: none;
        font-size: 16px;
    }
}

.date-picker.ng-section.summary-section {
    position: relative;

    &:after{
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Icon'%3E%3Cpath id='Vector' d='M12.6667 2.66675H3.33333C2.59695 2.66675 2 3.2637 2 4.00008V13.3334C2 14.0698 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0698 14 13.3334V4.00008C14 3.2637 13.403 2.66675 12.6667 2.66675Z' stroke='%231A2D4F' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath id='Vector_2' d='M2 6.66675H14' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath id='Vector_3' d='M10.6667 1.33325V3.99992' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath id='Vector_4' d='M5.33331 1.33325V3.99992' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A");
        display: block;
        height: 16px;
        width: 16px;
        position: absolute;
        top: 24px;
        right: 16px;
    }


    input {
        padding-left: 16px;
        padding-bottom: 8px;
        font-weight: 600;
        position: relative;
        z-index: 1;
    }
    .dropdown-menu.show {
        padding: 16px;
        width: 100%;
        .ngb-dp-navigation-select{
            gap: 16px;
        }
        .ngb-dp-months {
            justify-content: center;
            width: 100%;
            .ngb-dp-month{
                width: 100%;
                .ngb-dp-week{
                    margin-top: 8px;
                }
                .ngb-dp-day, .ngb-dp-weekday, .ngb-dp-week-number {
                    width: auto;
                    flex-grow: 1;
                    > div {
                        margin: 0 auto;
                    }
                }
                .ngb-dp-weekdays{
                    border: none;
                    border-top: 1px solid #EDECEE;
                    padding-top: 16px;
                    .ngb-dp-weekday{
                        font-weight: 700;
                    }
                }
                .btn-light:hover {
                    background-color: #E2ECFF;
                    border-color: #E2ECFF;
                }
            }
        }
    }
}

.date-range-picker {
    position: relative;

    .ngb-dp-day.disabled, .ngb-dp-day.hidden {
        cursor: default;
        pointer-events: none;
        color: #a3a393 !important;
    }

    .dropdown-menu.show {
        padding: 16px;
        width: 100%;
        min-width: 290px;
        z-index: 1002;
        .ngb-dp-navigation-select {
            gap: 16px;
        }
        .ngb-dp-months {
            justify-content: center;
            width: 100%;
            .ngb-dp-month {
                width: 100%;
                .ngb-dp-week {
                    margin-top: 8px;
                }
                .ngb-dp-day,
                .ngb-dp-weekday,
                .ngb-dp-week-number {
                    width: -webkit-fill-available;
                    > div {
                        margin: 0 auto;
                    }
                }
                .ngb-dp-weekdays {
                    border: none;
                    border-top: 1px solid #edecee;
                    padding-top: 16px;
                    .ngb-dp-weekday {
                        font-weight: 700;
                    }
                }
                .btn-light:hover {
                    background-color: #e2ecff;
                    border-color: #e2ecff;
                }

                .ngb-dp-day:has(.faded) {
                    background-color: rgba(2, 117, 216, 0.2);
                    color: black;
                }

                .ngb-dp-day {
                    &:has(.start-from.end-from) {
                        color: black;
                        border-radius: 16px;
                    }
                    &[tabindex="0"]:has(.start-from):not(:has(.end-from)) {
                        border-radius: 16px;
                    }
                    &[tabindex="0"]:has(.end-from):not(:has(.start-from)) {
                        & > .end-from {
                            position: relative;
                            &:before {
                                content: "";
                                width: 75%;
                                height: 100%;
                                background-color: rgba(2, 117, 216, 0.2);
                                left: 0;
                                top: 0;
                                position: absolute;
                                border-radius: 0 16px 16px 0px;
                            }
                        }
                    }
                    &[tabindex="-1"]:has(.start-from):not(:has(.end-from)) {
                        border-radius: 16px 0px 0px 16px;
                        background-color: rgba(2, 117, 216, 0.2);
                    }
                }
            }
        }

        .hidden {
            display: block !important;
        }
    }

    .custom-day {
        text-align: center;
        padding: 0.185rem 0.25rem;
        display: inline-block;
        height: 2rem;
        width: 2rem;
    }
    .custom-day.focused {
        background-color: #3c7dfd;
        border-radius: 16px;
    }

    .custom-day:hover,
    .custom-day.range:not(.faded) {
        background-color: #3c7dfd;
        border-radius: 16px;
        color: black;
    }
}

@include media-breakpoint-up(md) {
    .date-picker.ng-section.summary-section {
        width: 100%;
        max-width: 290px;
    }
}