.filter-section {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    &:before {
        content: "Filter by";
        width: 100%;
        margin-bottom: -15px;
        font-size: 14px;
        font-weight: 700;        
    }

    &.no-title {
        &:before {
            display: none;
        }
    }

    .summary-section {
        width: 100%;
        max-width: 100%;
    }
}

@include media-breakpoint-up(md) { 
    .filter-section {
        gap: 20px;
        .summary-section {
            width: 100%;
            max-width: 295px;
        }
        &.even-wrapper{
            .summary-section {
                width: auto;
                max-width: initial;
                flex-grow: 1;
                flex-basis: 0;
            }
        }
        &.mega-wrapper{
            .summary-section {
                width: 100%;
                max-width: 343px;
            }
        }        
    }
}
@include media-breakpoint-up(lg) { 
    .filter-section {
        gap: 24px;
    }
}

.filter-radio {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;

    &:before {
        content: "Display";
        font-size: 14px;
        font-weight: 700;
    }

    &.title-channels:before {
        content: "Display Channels";
    }
    &.title-makers:before {
        content: "Display Makers";
    }    

    .items {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        label {
            margin-left: 0.5em;
        }
    }
}

.flex-two-ends {
    .summary-section {
        width: 100%;
        max-width: 192px;        
    }
}

@include media-breakpoint-between(xs, md) {
    .flex-two-ends {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: flex-start;
        gap: 20px;
        .d-inline-block {
            display: block !important;
            width: 100%;
            button {
                width: 100%;
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .flex-two-ends {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 24px;
        justify-content: space-between;
        align-items: center;
        
        .summary-section.week,
        .single-dropdown-week {
            width: 100%;
            max-width: 340px;
        }
    }
}