:root {
  --photo-report-textBlack  : #212529;
  --photo-report-textRed    : red;
  --photo-report-textBlue   : #0000FF;
  --photo-report-allCategory: #8B90A3;
  --photo-report-ssdTotal   : #959AA1;
  --photo-report-blackTea   : rgb(160, 148, 152);
  --photo-report-coffee     : rgb(198, 156, 131);
  --photo-report-colaSSD    : rgb(244, 0, 9);
  --photo-report-energy     : rgb(240, 130, 88);
  --photo-report-juice      : rgb(252, 207, 77);
  --photo-report-nsTea      : #93AFB9;
  --photo-report-others     : rgb(188, 195, 209);
  --photo-report-otherSSD   : rgb(154, 100, 167);
  --photo-report-water      : rgb(77, 162, 214);
  --photo-report-sport      : rgb(79, 185, 193);
}
