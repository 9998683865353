body {
    &.pdf-printing {

        font-family: sans-serif !important;

        &::before {
            content: "LOADING...";
            background-color: #777777;
            width: 100%;
            height: 100%;
            display: block;
            position: fixed;
            z-index: 100000;
            margin: 0 auto;
            text-align: center;
            padding: 50vh;
            color: white;
        }

        main {
            margin: 0 !important;
            max-width: 100% !important;
        }

        .sidebarMenu {
            display: none !important;
        }

        .card-body {
            width: 100%;
            height: 100%;
        }

        .item-content {
            height: 28px;
            padding-right: 0.5rem !important;
            padding-left: 0.5rem !important;
        }

        .sort-column {
            margin-bottom: 1rem;
        }

        // https://boxicons.com/?query=down
        // don't use palettes
        // https://base64.guru/converter/encode/image
        .bx-up-arrow-alt:before {
            content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAL5JREFUSEvtlDsOgzAMQO0zwF1gpEPuRc+VoYxwF3qGIEtu5boJNq2QGJIJifg9/wDh5IMn86EKzA4falETuwcRn2G5mWS+4BYwfOC4yStxCRT8lbxLYgoEfAKAdwX8bEp2BRJOLWlil3gGqN+VZlIU5ABSQECPJCtoYz8kSLQxHy3QAilBwPsa5lFXUqygjf2oA3ICAlJCa5hpRl/HHLKMKAn2vokqMP8Y12qRme6/W1QFv3TAjDm0RSYtc2EDT1phGcdUJu8AAAAASUVORK5CYII=);
            font-size: 16px;
            display: inline-block;
        }

        .bx-down-arrow-alt:before {
            content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAALtJREFUSEvtVEEOgzAMc34GZ/jTeBScx88ypVNRFoWmRUPi0F6oRGPHdlrCzYtuxkcnCB1+lkU8gaVl2uqza1LQCcKJ+KtFPGOgFbtmPSPgGQutWLwO3ZClAIwXgJ02jLnQI+AJbwADCKNtKE3cmS9HoSKxBN4Zi1ccUwugCWrAiwqULV8LkPKQr6y8/7GwOgN7UHWrf4XgVQocJUmBDr80361Phdglb9ExWdHlaSKIwC5ncAU413QFoXsfrGNLGZkwY/IAAAAASUVORK5CYII=);
            font-size: 16px;
            display: inline-block;
        }

        .bx-wifi-0:before {
            content: ".";
        }

        .bxs-arrow-to-bottom:before {
            content: "";
            display: none;
        }

        .ng-select.ng-select-single .ng-select-container {
            line-height: 2em;
            height: 2em;
        }

        /* Show all tabs */
        .tab-content>.tab-pane {
            display: block;
        }

        .fade:not(.show) {
            opacity: 1;
        }

        .nav-tabs {
            display: none !important;
        }

        .top-navbar.navbar.is-sticky,
        .sticky-date {
            position: static !important;
            margin-top: 2em;
            margin-left: 2em;
        }

        .bx-paper-plane:before {
            content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAAXtJREFUWEftl12ywjAIhWFnujJ1Zded4ZBJOpgASZqf2wfzoqMt+XrgQIpwsYUX44EfUC0j/6IQEd0Q8a3BbQViEAB4AAB/8rrnYFuAFJAkzgsRn1KppUAOyF6gBpA9QETE8nONaOsFAFzIa2soqpGKVQWRdUJEf0uKupIWVoILtrD2dKCzIEk6IqL0HRELUzW7bBRkGtBZkFRbeZ85rdAgSHCSlpJuoBkgMT1FF46x2WW83oh4z235VUNOHwk9xHBNPp/CHoY6fG0X0OEAQe7BWI2wUIfjzVSI4zEYP/kzC1w0Q02dCCQfQIVWbR9TxzGsMWBMh/CzutEQkOgZnHNvLDSrMwVI7lYZnOb0dmK0p8zLh+wjxnVeyuRg3QZkqlUbrME0nhrafw0K5bcdbeMqQIdimWuLA/4KhcI5SBzAzLZh9aqZKbNml9o2VgOp8vdafkbKzKOqZRbvrXUUyOw3vc6V15+pIW5u6gF+BCTd2w00Y1Mvxg+opvAH+bgQNOGKnTkAAAAASUVORK5CYII=);
            width: 36px;
            height: 36px;
            display: inline-block;
        }

        .bx-bar-chart-square:before {
            content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAAOJJREFUWEftmPEKAjEIxvXJqiernqzryQyhhe22TDbBYw72zyHzt0+Fb4cQbGEwHkggrSJdhYjoDAC8PdaGiFvr4CYQEd0A4OpBIs68IyLn+Vo7oLcyD2eYcvylVqoFJNVhWZ+T4U6iFXYqaUBNWUcAq3ZYAKiaxu70FFXdFdIS1OXV4od7SEuQQIdRqAeqXcCth9YDst7YGl+m8e+SWRNY4xOoeJ9pU2YtgTU+S+ZRMmn6PzajZz9+fJeOdAGDZrWzo1MWwuRzr8R5BnEJQj0UhRmP85S2NurM+Pwdo6kZTqEXYpajNFUIyc0AAAAASUVORK5CYII=);
            width: 36px;
            height: 36px;
            display: inline-block;
        }

        .bx-wifi-0:before {
            content: "• ";
            line-height: 1em;
            font-size: 14px;
            vertical-align: sub;
            margin-right: 0.3em;
        }

        .hide-pdf-print {
            display: none !important;
        }

        .add-filter-container {
            display: none;
        }

        .search-button-container {
            display: none;
        }

        .csv-download {
            display: none;
        }

        select.form-control {
            display: none !important;
        }

        .week-label {
            margin-left: 20px;
            position: static;
        }

        .ng-section.summary-section {
            display: flex !important;
            border: none;
            white-space: nowrap;

            b {
                white-space: nowrap;
            }

            .ng-select .ng-arrow-wrapper {
                display: none;
            }

            * {
                overflow: visible;
                line-height: 2em;
                // border: 1px solid red;
            }
        }

        .callout {
            border: none;

            &:before {
                display: none;
            }
        }

        .filter-section {
            overflow: visible !important;
            text-overflow: ellipsis !important;
            $size: 15px;

            .ng-section b,
            .ng-select,
            .ng-select,
            .ng-select-container,
            .ng-value-container,
            .ng-value,
            .ng-value-label {
                padding: 0 !important;
                margin: 0 !important;
                line-height: $size !important;
                height: $size !important;
                min-height: $size !important;
                font-size: $size !important;
                display: inline-block !important;
                overflow: visible !important;
                text-overflow: ellipsis !important;
                position: static !important;
                text-align: left !important;
                border: none !important;
                vertical-align: top !important;
                appearance: none !important;
            }

            .ng-select,
            .ng-section.summary-section .form-control {
                margin-left: 0.5em !important;
                display: inline-block !important;
            }

            .ng-section.summary-section select.form-control {
                display: none !important;
            }
        }

        // Fix: 1205912841535837
        .flex-two-ends .single-dropdown-week {
            padding-left: 25px;

            b {
                margin: 0;
                line-height: 30px;
                font-size: 16px;
            }

            .ng-select-container {
                line-height: 30px;
            }
        }

        .search-section.none-pdf {
            display: none !important;
        }

        .filter-container-pdf {
            display: block !important;
        }

        .cdk-virtual-scroll-viewport {
            display: contents !important;
            position: relative;
            overflow: visible !important;
            contain: strict;
            transform: translateZ(0);
            will-change: scroll-position;
            -webkit-overflow-scrolling: touch;
        }

        .cdk-virtual-scroll-content-wrapper {
            position: static !important;
            top: 0;
            left: 0;
            contain: content;
        }



        .item-chanel-1,
        .item-chanel-2,
        .item-chanel-3 {
            b {
                font-weight: 800 !important;
                font-size: 12px !important;
            }
        }

        .item-chanel-2 {
            font-weight: 800 !important;
            font-size: 12px !important;
        }

        @import "pdf_print_area_intage_flash_full.scss";
        @import "pdf_print_area_intage_flash_vm_full.scss";
        @import "pdf_print_sku_deepdive.scss";
        @import "pdf_print_area_deepdive.scss";

        // District Print
        .market-size-district-route-progress,
        .market-size-district-route-by-district,
        .market-size-hq-progress-leve1,
        .market-size-hq-progress-leve2 {
            height: auto !important;
            overflow-y: auto;
            padding-top: 0px;
        }

        .table-container {
            height: auto !important;
            overflow-y: auto !important;
            width: 120%;
        }

        .app-add-more-filter {
            display: none !important;
        }

        .japanese-text {
            font-family: 'IPAMinchoRegular', sans-serif;
        }
    }

    &.pdf-printing-se {
        &::before {
            content: '';
        }

        &::after {
            content: '';
            border: 3.5px solid #fff;
            border-top: 3.5px solid #777;
            border-radius: 50%;
            width: 32px;
            height: 32px;
            animation: spin 0.75s linear infinite;
            position: fixed;
            top: 50%;
            left: 50%;
            margin-left: -16px;
            margin-top: -16px;
            z-index: 1000000;
        }

        .pdf_print_districttimeline {
            padding: 30px;
        }
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
