.tooltip-node {
  width: 180px;
  // FPT-ThinhLD4: [SENSE][1204064865410458] Sankey chart - details box size needs to be adjusted
  height: auto;
  // height: 90px;
  padding: 10px;
}

.cb-multi-select {
  cursor: pointer !important;
}

// Reset
.btn-check:focus + .btn, .btn:focus, .btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  outline: 0;
  box-shadow: none;
}

b, strong {
  font-weight: 600;
}