#pdf_print_area_intage_flash_full {
    width: 1780px;
    margin: 0 !important;
    padding: 0 !important;

    #pdf_print_area_0,
    #pdf_print_area_1,
    #pdf_print_area_2,
    #pdf_print_area_3,
    #pdf_print_area_4,
    #pdf_print_area_5 {
        padding-right: 1px; // Fix: 1205912841535843
        height: 2527.5px;
        // border: 1px solid red;
        // Note: This work only when u print the outter wrapper
    }

    #pdf_print_area_0 {
        // Fix the Week not showing
        padding-top: 2em;
    }

    .score-card-body .badge {
        margin: 1em;
    }
}