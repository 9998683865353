#pdf_print_sku_deepdive {
    width: 1800px;
    margin-top: 2em;

    .card {
        box-shadow: none !important;
        border-radius: 0 !important;
    }

    .card-body{
        overflow-x: hidden !important;
        overflow-y: hidden !important;
    }

    // .row > * {
    //     padding-right: 0 !important;
    // }

    .print-mb-0 {
        margin-bottom: 0 !important;
    }
}
