// Copy this to local and overide it
//  @import "~@ng-select/ng-select/themes/default.theme.css";

$containerHeight: 25px;

.ng-select.ng-select-opened>.ng-select-container {
    // background: #fff;
    border-color: none;
}

.ng-select.ng-select-opened>.ng-select-container:hover {
    box-shadow: none
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M112 328l144-144 144 144'/%3E%3C/svg%3E");
    display: inline-block !important;
    width: 16px !important;
    height: 16px !important;
    background-position: center;
    background-size: contain;  
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow:hover {
    border-color: transparent transparent #333
}

.ng-select.ng-select-opened.ng-select-top>.ng-select-container {
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.ng-select.ng-select-opened.ng-select-right>.ng-select-container {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.ng-select.ng-select-opened.ng-select-left>.ng-select-container {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

// .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
//     border-color: #007eff;
//     box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1)
// }

.ng-select.ng-select-disabled>.ng-select-container {
    background-color: #f9f9f9
}

.ng-select .ng-has-value .ng-placeholder {
    display: none
}

.ng-select .ng-select-container {
    color: #333;
    border-radius: 4px;
    height: $containerHeight;
    align-items: center;
    // margin-left: 16px;
    // margin-right: 16px;
    margin-bottom: 8px;
    background-color: transparent;
}

// .ng-select .ng-select-container:hover {
//     box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06)
// }

.ng-select .ng-select-container .ng-value-container {
    align-items: center;
    padding-left: 16px !important;
}

[dir="rtl"] .ng-select .ng-select-container .ng-value-container {
    padding-right: 10px;
    padding-left: 0
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: #999
}

.ng-select.ng-select-single .ng-select-container {
    height: $containerHeight
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    // top: 5px;
    left: 0;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 16px;
}

[dir="rtl"] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    padding-right: 10px;
    padding-left: 50px
}

.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value {
    background-color: #f9f9f9;
    border: 1px solid #e6e6e6
}

.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label {
    padding: 0 5px
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    padding-top: 5px;
    padding-left: 7px;
    font-weight: 600;

    flex-wrap: nowrap !important;
	text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    padding-right: 7px;
    padding-left: 0
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    font-size: .9em;
    margin-bottom: 5px;
    color: #333;
    background-color: #E2ECFF;
    border-radius: 2px;
    margin-right: 5px
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    margin-right: 0;
    margin-left: 5px
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
    background-color: #f9f9f9
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
    padding-left: 5px
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
    padding-left: 0;
    padding-right: 5px
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
    display: inline-block;
    padding: 1px 5px
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
    display: inline-block;
    padding: 1px 5px
}

// .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
//     background-color: #d1e8ff
// }

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-right: 1px solid #b8dbff
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-left: 1px solid #b8dbff;
    border-right: none
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
    border-left: 1px solid #b8dbff
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
    border-left: 0;
    border-right: 1px solid #b8dbff
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
    padding: 0 0 3px 3px
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
    padding: 0 3px 3px 0
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input>input {
    color: #000
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: 5px;
    padding-bottom: 5px;
    padding-left: 3px
}

[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    padding-right: 3px;
    padding-left: 0
}

.ng-select .ng-clear-wrapper {
    color: #999
}

// .ng-select .ng-clear-wrapper:hover .ng-clear {
//     color: #D0021B
// }

.ng-select .ng-spinner-zone {
    padding: 5px 5px 0 0
}

[dir="rtl"] .ng-select .ng-spinner-zone {
    padding: 5px 0 0 5px
}

.ng-select .ng-arrow-wrapper {
    // width: 25px;
    // // padding-right: 5px
    // top: -6px;
    // right: -5px;
    top: 0px;
    right: 16px;
    background-color: white;
    height: 100%;
}

[dir="rtl"] .ng-select .ng-arrow-wrapper {
    padding-left: 5px;
    padding-right: 0
}

// .ng-select .ng-arrow-wrapper:hover .ng-arrow {
//     border-top-color: #666
// }

.ng-select .ng-arrow-wrapper .ng-arrow {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M112 184l144 144 144-144'/%3E%3C/svg%3E");
    display: inline-block !important;
    width: 16px !important;
    height: 16px !important;
    background-position: center;
    background-size: contain;
    top: -6px;
}

.ng-dropdown-panel {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    left: 0
}

.ng-dropdown-panel.ng-select-top {
    bottom: 100%;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-color: #e6e6e6;
    margin-bottom: -1px
}

.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px
}

.ng-dropdown-panel.ng-select-right {
    left: 100%;
    top: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-color: #e6e6e6;
    margin-bottom: -1px
}

.ng-dropdown-panel.ng-select-right .ng-dropdown-panel-items .ng-option:first-child {
    border-top-right-radius: 4px
}

.ng-dropdown-panel.ng-select-bottom {
    // top: 100%;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border: none;
    margin-top: 0;
    top: 30px;
    //min-width: 250px;
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.ng-dropdown-panel.ng-select-left {
    left: -100%;
    top: 0;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-color: #e6e6e6;
    margin-bottom: -1px
}

.ng-dropdown-panel.ng-select-left .ng-dropdown-panel-items .ng-option:first-child {
    border-top-left-radius: 4px
}

.ng-dropdown-panel .ng-dropdown-header {
    border-bottom: 1px solid #ccc;
    padding: 5px 7px
}

.ng-dropdown-panel .ng-dropdown-footer {
    border-top: 1px solid #ccc;
    padding: 5px 7px
}

.ng-dropdown-footer {
    button {
        width: 100%;
        background-color: #3C7DFD;
        border-color: #3C7DFD;
    }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
    user-select: none;
    padding: 8px 10px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    cursor: pointer
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
    cursor: default
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
    background-color: #f5faff
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
    color: rgba(0, 0, 0, 0.54);
    background-color: #E2ECFF;
    font-weight: 600
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    padding: 8px 10px;

    &:before{
        content: "";
        display: inline-block;
        height: 16px;
        width: 16px;
        vertical-align: middle;
        margin-right: 10px;
    }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: #333;
    background-color: #E2ECFF;
    &:before{
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M416 128L192 384l-96-96'/%3E%3C/svg%3E");  
    }  
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
    font-weight: normal;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: #f5faff;
    color: #333;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
    color: #ccc
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
    padding-left: 22px
}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
    padding-right: 22px;
    padding-left: 0
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
    font-size: 80%;
    font-weight: 400;
    padding-right: 5px
}

[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
    padding-left: 5px;
    padding-right: 0
}

[dir="rtl"] .ng-dropdown-panel {
    direction: rtl;
    text-align: right
}


// Extra
.summary-section {
    background: #FFFFFF;    
    border: 1px solid #1A2D4F;
    border-radius: 12px;
    padding: 0;

    .scroll-host {
        .ng-option {
            input {
                display: none;
            }
        }
    }

    .ng-select {      
        &.ng-select-single {
            .ng-select-container .ng-value-container .ng-value {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
            }    
            .ng-clear-wrapper {
                display: none;
            }
        }
    }
    
    b {
        padding: 0 !important;
        color: #818EA5;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        display: block;
        margin-top: 8px;
        margin-left: 16px;
        margin-right: 16px;
    }

    // Single select
    select.form-control {
        border: none;
        padding-left: 0;
        appearance: auto;
        width: 100%;
        font-weight: 600;
    }

    input.form-control {
        border: none;
        padding: 0;
        appearance: auto;
        background: transparent;
    }    
}

// Fix the tag selector
.select-key.ng-section.summary-section {
    .ng-select .ng-select-container {
        height: auto;
    }
}


.week.ng-section.summary-section{
    padding: 8px 16px;
    position: relative;
    z-index: 1;
    b {
        margin: 0;
        padding: 0;
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: transparent;
    }
    &:after {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Icon'%3E%3Cpath id='Vector' d='M12.6667 2.66675H3.33333C2.59695 2.66675 2 3.2637 2 4.00008V13.3334C2 14.0698 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0698 14 13.3334V4.00008C14 3.2637 13.403 2.66675 12.6667 2.66675Z' stroke='%231A2D4F' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath id='Vector_2' d='M2 6.66675H14' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath id='Vector_3' d='M10.6667 1.33325V3.99992' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath id='Vector_4' d='M5.33331 1.33325V3.99992' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A");
        display: block;
        height: 16px;
        width: 16px;
        position: absolute;
        top: 20px;
        right: 16px;
        z-index: -1;
    }
}
.week-start.ng-section.summary-section {
    padding: 8px 16px;
    position: relative;
    z-index: 1;
    b {
        margin: 0;
        padding: 0;
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: transparent;
    }
    &:after {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Icon'%3E%3Cpath id='Vector' d='M12.6667 2.66675H3.33333C2.59695 2.66675 2 3.2637 2 4.00008V13.3334C2 14.0698 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0698 14 13.3334V4.00008C14 3.2637 13.403 2.66675 12.6667 2.66675Z' stroke='%231A2D4F' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath id='Vector_2' d='M2 6.66675H14' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath id='Vector_3' d='M10.6667 1.33325V3.99992' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath id='Vector_4' d='M5.33331 1.33325V3.99992' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A");
        display: block;
        height: 16px;
        width: 16px;
        position: absolute;
        top: 20px;
        right: 16px;
        z-index: -1;
    }    
}
@include media-breakpoint-up(md) {
    .week-start.ng-section.summary-section {
        width: 100%;
        max-width: 192px;
    }
}

// Those still using native expect date/week picker
.native.ng-section.summary-section{
    padding: 8px 16px;
    position: relative;
    z-index: 1;
    b {
        margin: 0;
        padding: 0;
    }
    select {
        margin: 0 !important;
        padding: 0 !important;
        width: 100% !important;
        -webkit-appearance: initial;
        -moz-appearance: initial;
        appearance: initial;
        background-color: transparent;        
    }
    &:after {
        content: "";
        background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M112 184l144 144 144-144'/%3E%3C/svg%3E");    
        display: block;
        height: 16px;
        width: 16px;
        position: absolute;
        top: 20px;
        right: 16px;
        z-index: -1;
    } 
}