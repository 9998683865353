/*
Hide NaN label
https://app.asana.com/0/1204421777568712/1204644951838196
*/
.apexcharts-data-labels {
    rect[y*="-"][fill="#ff2626"] {
        display: none;

        +text {
            display: none;
        }
    }
}

.apexcharts-legend {
    justify-content: flex-start !important;
    margin: 0;
    gap: 16px;
    // pointer-events: none;
}

// This is the legend when only one item
.single-series-container {
    text-align: left !important;
}