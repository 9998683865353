#deep-dive-data{
    th {
        font-weight: 700 !important;
    }
    th, td {
        padding: 8px !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }
    .background-total {
        background: #C9F5DF !important;
    }
    .shadow-title {
        white-space: pre-wrap;
    }
    .shadow-column {
        display: none;
    }
    .division {
        min-width: 6rem;
        left: 0px;
        background-color: white;
        font-weight: 700;
        padding-left: 0 !important;
    }
}