table.intage-market-size {
    page-break-inside: avoid;

    .text-red {
        color: unset;
    }

    .first-left-col, .second-left-col {
        padding: 20px !important;
        .maker-logo, .maker-logo-second {
            max-width: 100px;
        }
    }
}