.swal2-container{
    &.swal2-backdrop-show, &.swal2-noanimation {
        background: rgb(26 45 79 / 50%) !important;
    }
    .swal2-popup {
        padding: 48px;
        width: 100%;
        max-width: 657px;
        border-radius: 16px;
        .swal2-icon{
            margin-top: 0;
            margin-bottom: 15px;
            &.swal2-warning {
                background-color: #F29B2E;
                border-color: #F29B2E;
                color: #F29B2E;
                height: 72px;
                width: 72px;
                background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'%3E%3Cpath d='M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z' fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/%3E%3Cpath d='M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z' fill='white' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/%3E%3Cpath fill='white' d='M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z'/%3E%3C/svg%3E");
                background-size:55%;
                background-repeat: no-repeat;
                background-position: center;
                .swal2-icon-content {
                    display: none;
                }
            }
        }
        .swal2-title {
            padding: 0;
            p{
                margin: 0;
                margin-bottom: 24px;
                line-height: 1.4em;
                color: #1A2D4F;
            }
            .line1 {
                font-size: 30px;
                font-weight: 700;
            }
            .line2 {
                font-size: 20px;
                font-weight: 400;
            }
            .line3 {
                font-size: 16px;
                font-weight: 400;
            }                        
        }
        .swal2-actions{
            margin: 0;
            padding: 0;
        }
        .swal2-styled {
            border-radius: 12px;
            background-color: #3C7DFD;
            padding: 14px 24px;
        }
    }
}